import React from 'react'
import { useBookingFormController } from 'features/Booking/model/hooks/useBookingFormController'
import { NomenclaturesInput } from 'entities/bookings/ui'
import { Select } from 'shared/components/Select'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import { StyledBookingsContainer } from './styles'
import { Tab, Tabs } from 'shared/components/Tabs'
import { formatDate } from '../../../model/hooks/useBookingFormDateTimeHelper'
import { AreaButton } from './BookingFormAreaButton'
import { ActiveBookingFormTab } from '../../../model/slices'

export const BookingForm = () => {
    const {
        start,
        end,
        activeBooking,
        areasResources,
        activeBookingId,
        bookings,
        membersCount,
        activeTab,
        disabledTab,
        isButtonDisabled,

        setActiveTab,
        onAddNomenclature,
        onRemoveNomenclature,

        onChangeActiveBookingId,
        onAddBooking,
        onRemoveBooking,

        onEditMembersCount,

        onEditArea,
        onEditStartDate,
        onEditEndDate,
        onEditStartTime,
        onEditEndTime,

        onGoNext,
    } = useBookingFormController()

    const BookingFormDateSettings = () => (
        <div className='row'>
            <Select
                label='Помещение'
                placeholder='Выберите помещение'
                onChange={onEditArea}
                value={activeBooking?.areaId ?? null}
                options={areasResources?.map(res => ({
                    value: res.id,
                    title: res.title,
                }))}
                required
            />
            <Input
                name='membersCount'
                type='number'
                min={0}
                onChange={onEditMembersCount}
                value={membersCount ?? 0}
                label='Колличество человек'
                required
            />
            <Input
                name='startDate'
                type='date'
                min={formatDate(new Date())}
                max={end?.date}
                onChange={onEditStartDate}
                value={start?.date}
                label='Дата начала'
                required
            />
            <Input
                name='endDate'
                type='date'
                min={start?.date}
                onChange={onEditEndDate}
                value={end?.date}
                label='Дата конца'
                required
            />
            <Input
                name='startTime'
                type='time'
                onChange={onEditStartTime}
                value={start?.time}
                label='Время начала'
                required
            />
            <Input
                name='endTime'
                type='time'
                onChange={onEditEndTime}
                value={end?.time}
                label='Время конца'
                required
            />
        </div>
    )

    return (
        <div className='content'>
            <Button onClick={onAddBooking}>Добавить зал</Button>

            <StyledBookingsContainer>
                {bookings.map(booking => (
                    <AreaButton
                        onClick={() => onChangeActiveBookingId(booking.id)}
                        onCrossClick={() => onRemoveBooking(booking.id)}
                        text={
                            booking?.areaName?.length
                                ? booking.areaName
                                : 'Новый зал'
                        }
                        isActive={activeBookingId === booking.id}
                    />
                ))}
            </StyledBookingsContainer>

            <Tabs
                onTabChange={setActiveTab}
                activeTabIndex={activeTab}
                disabledTabIndex={disabledTab}
            >
                <Tab label='Дата и время'>
                    <BookingFormDateSettings />
                </Tab>
                <Tab label='Еда и оборудование'>
                    <NomenclaturesInput
                        values={activeBooking?.nomenclatures}
                        onAddNomenclature={onAddNomenclature}
                        onRemoveNomenclature={onRemoveNomenclature}
                    />
                </Tab>
            </Tabs>
            <Button disabled={isButtonDisabled} onClick={onGoNext}>
                {activeTab === ActiveBookingFormTab.Booking
                    ? 'Сохранить'
                    : 'Далее'}
            </Button>
        </div>
    )
}
