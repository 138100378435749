import { createSlice } from '@reduxjs/toolkit'
import {
    ActiveBookingFormTab,
    BookingMode,
    BookingState,
    BookingStep,
} from './types'
import {
    addBookingAction,
    addEmptyBookingAction,
    addNomenclatureToBookingAction,
    clearBookingStateAction,
    editBookingAction,
    removeBookingAction,
    removeNomenclatureToBookingAction,
    replaceBookingTempClientIdToOriginIdAction,
    setActiveBookingIdAction,
    setBookingActiveTabAction,
    setBookingCounterpartyIdAction,
    setBookingCurrentStepAction,
    setBookingFormModeAction,
    setBookingOrderIdAction,
    setBookingsAction,
    setMembersCountToBookingAction,
    setOrderEditStateInitialisingAction,
    setOrderTotalPriceAction,
    setOriginalOrderAction,
} from './actions'

export const initialState: BookingState = {
    currentStep: BookingStep.GeneralInfo,
    mode: BookingMode.Create,
    activeBookingId: null,
    originalOrder: null,
    bookingActiveTab: ActiveBookingFormTab.Booking,
    isEditStateInitialising: false,

    orderId: null,
    counterpartyId: null,
    totalPrice: null,

    bookings: [],
}

export const bookingSidebarFormSlice = createSlice({
    name: 'bookingSidebarForm',
    initialState,
    reducers: {
        setActiveBookingId: setActiveBookingIdAction,
        setBookingCurrentStep: setBookingCurrentStepAction,
        clearBookingState: clearBookingStateAction,
        setBookingActiveTab: setBookingActiveTabAction,
        setOrderEditStateInitialising: setOrderEditStateInitialisingAction,

        addBooking: addBookingAction,
        addEmptyBooking: addEmptyBookingAction,
        removeBooking: removeBookingAction,
        editBooking: editBookingAction,

        setBookingCounterpartyId: setBookingCounterpartyIdAction,
        setBookingOrderId: setBookingOrderIdAction,
        setMembersCountToBooking: setMembersCountToBookingAction,
        replaceBookingTempClientIdToOriginId:
            replaceBookingTempClientIdToOriginIdAction,
        addNomenclatureToBooking: addNomenclatureToBookingAction,
        removeNomenclatureToBooking: removeNomenclatureToBookingAction,
        setOrderTotalPrice: setOrderTotalPriceAction,

        setBookingFormMode: setBookingFormModeAction,
        setBookings: setBookingsAction,
        setOriginalOrder: setOriginalOrderAction,
    },
})

export const {
    setActiveBookingId,
    setBookingCurrentStep,
    clearBookingState,
    setBookingActiveTab,
    setOrderEditStateInitialising,

    addBooking,
    addEmptyBooking,
    removeBooking,
    editBooking,

    setBookingCounterpartyId,
    setBookingOrderId,
    setMembersCountToBooking,
    replaceBookingTempClientIdToOriginId,
    addNomenclatureToBooking,
    removeNomenclatureToBooking,
    setOrderTotalPrice,

    setBookingFormMode,
    setBookings,
    setOriginalOrder,
} = bookingSidebarFormSlice.actions

export default bookingSidebarFormSlice.reducer
