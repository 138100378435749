export const GeneralData = [
    [
        { 
            name: "name", 
            label: "Название", 
            placeholder: "Название", 
            type: "text",
            disabled: false,
            required: true,
        }
    ],
    [
        { 
            name: "price_per_hour", 
            label: "Цена, ₽/час", 
            placeholder: "₽ 0", 
            type: "number",
            disabled: false,
            required: true,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_price_info.more_than_three_hour_price_per_hour", 
            label: "Цена >3ч, ₽/час", 
            placeholder: "₽ 0", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_price_info.morning_price_per_hour", 
            label: "Утро, ₽/час", 
            placeholder: "₽ 0",
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_price_info.evening_price_per_hour", 
            label: "Вечер, ₽/час", 
            placeholder: "₽ 0",
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "prepayment", 
            label: "Предоплата", 
            placeholder: "0%", 
            type: "text",
            disabled: false,
            maskConfig: {
                mask: Number,
                min: 0,
                max: 100,
            }
        },
    ],
    [
        { 
            name: "ad_price_info.morning_hours", 
            label: "Утренние часы", 
            placeholder: "04:00 - 10:00", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_price_info.evening_hours", 
            label: "Вечерние часы", 
            placeholder: "20:00 - 00:00", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_booking_info.unavailable_refund_period_days", 
            label: "Недоступный период для возврата средств", 
            placeholder: "7 дней", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        }
    ],
    [
        { 
            name: "ad_booking_info.available_online_booking_time", 
            label: "Доступное время для онлайн брони", 
            placeholder: "10:00 - 13:00, 16:00 - 20:00", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_booking_info.available_booking_time", 
            label: "Доступное время брони", 
            placeholder: "22:00 - 03:00", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "square", 
            label: "Площадь помещения", 
            placeholder: "30м2",
            type: "number",
            disabled: false,
            required: true,
            maskConfig: { mask: Number },
        }
    ],
    [
        { 
            name: "ad_booking_info.blocking_before_minutes", 
            label: "Блокировка бронирования ДО, мин", 
            placeholder: "0", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
        { 
            name: "ad_booking_info.blocking_after_minutes", 
            label: "Блокировка бронирования ПОСЛЕ, мин", 
            placeholder: "0", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
        },
    ]
];