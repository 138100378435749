import moment from 'moment'
import { useSelector } from 'react-redux'
import { Nomenclature, OrderBooking } from 'entities/orders/model'
import { selectBookings, selectOrderTotalPrice } from '../../../model/slices'
import {
    StyledDescriptionItem,
    StyledDescriptionPrice,
    StyledFooterColumn,
    StyledFooterContainer,
    StyledFooterRow,
    StyledItemsContainer,
    StyledTitle,
} from './styles'

export const Footer = () => {
    const totalPrice = useSelector(selectOrderTotalPrice)
    const currentFormBookings = useSelector(selectBookings)

    if (!totalPrice || !currentFormBookings?.length) {
        return null
    }

    const getBookingRange = (booking: OrderBooking) => {
        if (!booking?.startTime || !booking?.endTime) {
            return ''
        }

        const start = moment(booking.startTime).format('MMM Do HH:mm')
        const end = moment(booking.endTime).format('MMM Do HH:mm')

        return `${start} - ${end}`
    }

    const getNomenclatureName = (nomenclature: Nomenclature) => {
        if (!nomenclature?.name) {
            return ''
        }

        return `${nomenclature.name} (${nomenclature.count})`
    }

    return (
        <StyledFooterContainer>
            <StyledTitle>Итого: ₽{totalPrice}</StyledTitle>

            <StyledItemsContainer>
                {currentFormBookings.map(booking => (
                    <StyledFooterColumn>
                        <StyledFooterRow>
                            <StyledDescriptionItem>
                                Аренда: {booking.areaName}:{' '}
                                {getBookingRange(booking)}
                            </StyledDescriptionItem>
                            <StyledDescriptionPrice>
                                ₽{booking?.areaPrice}
                            </StyledDescriptionPrice>
                        </StyledFooterRow>
                        {Boolean(booking?.nomenclatures?.length) && (
                            <StyledFooterRow>
                                <StyledDescriptionItem>
                                    Дополнительно:{' '}
                                    {booking.nomenclatures.map(nomenclature => (
                                        <span>
                                            {getNomenclatureName(nomenclature)}
                                        </span>
                                    ))}
                                </StyledDescriptionItem>
                                <StyledDescriptionPrice>
                                    ₽{booking?.nomenclaturesPrice}
                                </StyledDescriptionPrice>
                            </StyledFooterRow>
                        )}
                    </StyledFooterColumn>
                ))}
            </StyledItemsContainer>
        </StyledFooterContainer>
    )
}
