import { GetBookingsRequest } from '../types'
import { GetBookingsRequestDTO } from '../dtoTypes'

export const adaptBookingsRequestToDTO = (
    request?: GetBookingsRequest,
): GetBookingsRequestDTO => {
    return {
        start_time: request?.startTime
            ? request.startTime.toISOString()
            : undefined,
        end_time: request?.endTime ? request.endTime.toISOString() : undefined,
        order_by: request?.orderBy ?? 'start_time',
        counterparty_type: request?.counterpartyType ?? 0,
        page: request?.page?.toString(),
        size: request?.size?.toString() ?? '50',
        order_asc: request?.orderAsc?.toString() ?? 'false',
        search_param: request?.searchParam,
    }
}
