import styled from "styled-components";

export const WrapperModal = styled.div`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const WrapperModalContent = styled.div`
    border-radius: 16px;
    background: #fff;
    padding: 20px;
    width: 100%;
    max-width: 348px;
    &.modal-litle-size {
        max-width: 407px;
    }
    .modalcontent__btns {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        button {
            width: 100%;
        }
        &--outline {
            border: 1px solid #5E81F4;
            background: none;
            span {
                color: #5E81F4;
            }
        }
    }
    .blockWithComment {
        display: flex;
        flex-direction: column;
    }
    textarea {
        margin-top: 10px;
        border-radius: 4px;
        border: 1px solid #E1E4EA;
        padding: 10px;
        height: 70px;
        color: #8181A5;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        resize: none;
    }
`;

export const ModalContentTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E1E4EA;
    margin-bottom: 10px;
    .modal-title {
        color: #1C1C1C;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
`;

export const ModalClose = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
`;