import { useEffect, useRef, useState } from "react";
import { StyledUploadFile, UploadFileButton, UploadFileList } from "./style"
import { Button } from "shared/components/Button";
import { Icon } from "shared/components/Icons";

interface IPropsFile {
    id: number;
    name: string;
    hash_name?: string;
    read_url?: string;
    file_name?: string;
}

interface IPropsUploadFile {
    file: IPropsFile[];
    disabled?: boolean;
    themeType?: "default" | "onlytitle";
    onChangeFile?: (files: IPropsFile[]) => void;
    onNewFilesChange?: (newFiles: IPropsFile[]) => void;
    onRemovedFilesChange?: (removedFiles: IPropsFile[]) => void;
}

export const UploadFile = ({
    file: initialFiles,
    disabled = true,
    themeType = "default",
    onChangeFile,
    onNewFilesChange,
    onRemovedFilesChange
}: IPropsUploadFile) => {

    const [files, setFiles] = useState<IPropsFile[]>([]);
    const [newFiles, setNewFiles] = useState<IPropsFile[]>([]);
    const [removedFiles, setRemovedFiles] = useState<IPropsFile[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
        if (initialFiles.length > 0) {
            const filesWithIds = initialFiles.map(file => ({
                ...file,
                id: Math.random()
            }));
            setFiles(filesWithIds);
        }
    }, [initialFiles]);

    useEffect(() => {
        onChangeFile && onChangeFile(files);
    }, [files, onChangeFile]);

    useEffect(() => {
        onNewFilesChange && onNewFilesChange(newFiles);
    }, [newFiles, onNewFilesChange]);

    useEffect(() => {
        onRemovedFilesChange && onRemovedFilesChange(removedFiles);
    }, [removedFiles, onRemovedFilesChange]);

    const handleDelete = (e: any, id: number) => {
        e.preventDefault();
        const fileToDelete = files.find(file => file.id === id);
        if (fileToDelete) {
            if (files.some(file => file.id === id)) {
                setRemovedFiles(prevRemovedFiles => [...prevRemovedFiles, fileToDelete]);
            }
        }
        const updatedFiles = files.filter(file => file.id !== id);
        setFiles(updatedFiles);
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList) {
            const newFilesToAdd: any = [];
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                const newFile = { id: Math.random(), name: file.name, file: file };
                newFilesToAdd.push(newFile);
            }
            const updatedFiles = [...files, ...newFilesToAdd];
            setFiles(updatedFiles);
            setNewFiles(prevNewFiles => [...prevNewFiles, ...newFilesToAdd]);
        }
    };

    const handleAddFileClick = () => {
        fileInputRef.current?.click();
    };

    const openInNewTab = (e: any, fileUrl: any) => {
        e.preventDefault();
        const newWindow = window.open(fileUrl, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <StyledUploadFile>
            {files.length !== 0 && 
                <UploadFileList>
                    {files.map((item, index) => (
                        <div key={index} className="row">
                            <div className="fileName">
                                {themeType === "onlytitle" ? 
                                    (<div className="onlytitle">{item.name}</div>) 
                                    : 
                                    (<>
                                        <div className="box"></div>
                                        <span>{item.name ? item.name : item.file_name}</span>
                                    </>)
                                }
                            </div>
                            {item.read_url && (
                                <Button onClick={(e) => openInNewTab(e, item.read_url)} className="buttonFile" themeType="outline-2" style={{borderColor: "#E1E4EA", background: "none"}}>
                                    <Icon name="download" />
                                </Button>
                            )}
                            {disabled &&
                                <Button onClick={(e) => handleDelete(e, item.id)} className="buttonFile" themeType="outline-2" style={{borderColor: "#FC3F1D", background: "#FFF1EF"}}>
                                    <Icon name="delete" />
                                </Button>
                            }
                        </div>
                    ))}
                </UploadFileList>
            }
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelect}
                multiple
            />
            {disabled && <UploadFileButton onClick={handleAddFileClick}>+Добавить фотографии документов</UploadFileButton>}
        </StyledUploadFile>
    );
};