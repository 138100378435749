import { axiosApi } from 'shared/api/axios'
import { BookingEndpoint } from './constants'
import { GetBookingsResponseDTO } from '../model/dtoTypes'
import { GetBookingsRequest } from '../model'
import { adaptBookingDTO } from '../model/adapters/adaptBookingDTO'
import { adaptBookingsRequestToDTO } from '../model/adapters/adaptBookingsRequestToDTO'

export const getBookings = async (queries?: GetBookingsRequest) => {
    try {
        const params = adaptBookingsRequestToDTO(queries)
        const response = await axiosApi.get<GetBookingsResponseDTO>(
            BookingEndpoint.bookings,
            {
                params,
            },
        )
        return response.data.bookings.map(adaptBookingDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
