import { OrdersApi } from 'entities/orders/api'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectActiveBookingId,
    setActiveBookingId,
    setBookingCounterpartyId,
    setBookings,
    setOrderEditStateInitialising,
    setOrderTotalPrice,
    setOriginalOrder,
} from '../slices'

export const useFillStore = () => {
    const dispatch = useDispatch()
    const activeBookingId = useSelector(selectActiveBookingId)

    const updateBookings = async (
        orderId: number | null,
        bookingIdToActive?: number | null,
    ) => {
        if (!orderId) {
            return
        }

        dispatch(setOrderEditStateInitialising({ isInitialising: true }))

        const order = await OrdersApi.getDetailOrder(orderId)
        dispatch(setBookingCounterpartyId({ id: order.counterpartyId }))
        dispatch(setBookings({ bookings: order.bookings }))
        dispatch(
            setActiveBookingId({
                bookingId:
                    bookingIdToActive ??
                    activeBookingId ??
                    order.bookings?.[0]?.id,
            }),
        )
        dispatch(setOriginalOrder({ order }))
        dispatch(setOrderTotalPrice({ price: order.totalPrice }))

        dispatch(setOrderEditStateInitialising({ isInitialising: false }))
    }

    return updateBookings
}
