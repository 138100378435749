import { AdPriceInfoDTO } from '../dtoTypes'
import { AdPriceInfo } from '../types'
import { adaptTimePeriodDTO } from './adaptTimePeriodDTO'

export const adaptAdPriceInfoDTO = (infoDTO: AdPriceInfoDTO): AdPriceInfo => {
    return {
        moreThanThreeHourPricePerHour:
            infoDTO.more_than_three_hour_price_per_hour,
        morningPricePerHour: infoDTO.morning_price_per_hour,
        eveningPricePerHour: infoDTO.evening_price_per_hour,
        morningHours: adaptTimePeriodDTO(infoDTO.morning_hours),
        eveningHours: adaptTimePeriodDTO(infoDTO.evening_hours),
    }
}
