import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { GeneralData } from "../model/generalData";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { initialValues } from "../model/initialValues";
import { validateSchema } from "../model/validateSchema";
import { postAreas } from "../api/postAreas";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { AREASPROFILE } from "shared/consts/routingConsts";

interface IPropsEmployeesCreate {
    setDisabled: (e: boolean) => void;
    setLoading: (e: boolean) => void;
    formikRef: React.Ref<FormikHelpers<any>>;
}

interface IPropsFile {
    id: number;
    name: string;
    file?: File;
}

export const AreasCreate = ({
    setDisabled,
    setLoading,
    formikRef
}:IPropsEmployeesCreate) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [photo, setPhoto] = useState<File | null>(null);
    
    const { handleFileUpload } = useFileUploadUp();

    const handlePhotoChange = (photo: File) => {
        setPhoto(photo);
    };

    const handleSubmit = async (values: any, actions: any) => {
        setLoading(true);
        try {
            const profileResponse = await postAreas({
                ...values
            });


            if (profileResponse.status !== 201) {
                dispatch(showNotification({
                    message: "Ошибка",
                    type: "error"
                }));
                return;
            }

            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));

            navigate(`${AREASPROFILE}/${profileResponse.data.id}`);
            actions.setSubmitting(false);
        } catch (err) {
            console.error(err);
            actions.setSubmitting(false);
        } finally {
            setLoading(false);
        }
        console.log('Форма отправлена', values);
    };

    return (
        <Formik
            innerRef={formikRef as any}
            key="personalForm"
            initialValues={{}}
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
        >
            {({ isValid, dirty }) => {
                setDisabled(!(isValid && dirty));
                return (
                    <Form>
                        <WrapperFormRow title="Помещение" formData={GeneralData}  />
                    </Form>
                )
            }}
        </Formik>
    )
}