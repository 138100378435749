import * as Yup from 'yup';

export const validateSchema = Yup.object().shape({
    username: Yup.string().required().min(3),
    password: Yup.string().required().min(6),
    phone: Yup.string().required().matches(/^[\d+()-\s]+$/),
    email: Yup.string().required().email(),
    user_ad_personal_info: Yup.object().shape({
        first_name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
    })
});