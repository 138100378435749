import { axiosApi } from 'shared/api/axios'
import { NomenclaturesEndpoint } from './constants'
import { GetNomenclaturesResponseDTO } from '../model/dtoTypes'
import { adaptNomenclatureDTO } from '../model/adapters/adaptNomenclatureDTO'

export const getNomenclatures = async () => {
    try {
        const response = await axiosApi.get<GetNomenclaturesResponseDTO>(
            NomenclaturesEndpoint.nomenclature,
        )
        return response.data.nomenclatures.map(adaptNomenclatureDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
