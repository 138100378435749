import { useDispatch, useSelector } from 'react-redux'
import {
    BookingMode,
    clearBookingState,
    selectBookingCounterpartyId,
    selectBookingMode,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    selectBookings,
} from '../../../Booking/model/slices'
import { RootState } from 'shared/redux/store'
import { useBlocker } from 'react-router-dom'
import { CLIENTSCREATE } from 'shared/consts/routingConsts'
import { useEffect, useMemo } from 'react'
import deepEqual from 'fast-deep-equal'
import { closeSidebar } from 'shared/redux/slice/sidebar'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'

export const useNavigationBlocker = () => {
    const dispatch = useDispatch()

    const { isOpen } = useSelector((state: RootState) => state.sidebar)

    const orderId = useSelector(selectBookingOrderId)
    const mode = useSelector(selectBookingMode)
    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const currentFormBookings = useSelector(selectBookings)

    const blocker = useBlocker(({ nextLocation }) => {
        if (nextLocation.pathname.includes('calendar')) {
            return false
        }

        if (nextLocation.pathname === CLIENTSCREATE) {
            return false
        }
        return isOpen
    })

    const isDirty = useMemo(() => {
        if (mode === BookingMode.Create) {
            return !!orderId || !!counterpartyId || !!currentFormBookings.length
        }
        if (mode === BookingMode.Edit) {
            if (!originalOrder) {
                return false
            }
            if (originalOrder.bookings.length !== currentFormBookings.length) {
                return true
            }
            return originalOrder.bookings.some(originalBooking => {
                const currentBooking = currentFormBookings.find(
                    booking => booking.id === originalBooking.id,
                )
                return !deepEqual(originalBooking, currentBooking)
            })
        }
        return false
    }, [mode, orderId, counterpartyId, currentFormBookings, originalOrder])

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const resetBooking = () => {
                dispatch(clearBookingState())
                dispatch(closeSidebar())
                blocker.proceed()
            }

            if (isDirty) {
                dispatch(
                    openModal({
                        isOpen: true,
                        content: (
                            <>
                                У вас есть несохраненные изменения. Вы уверены,
                                что хотите уйти?
                            </>
                        ),
                        config: {
                            title: 'Вы точно хотите покинуть страницу?',
                            btns: [
                                {
                                    onClick: () => {
                                        resetBooking()
                                        dispatch(closeModal())
                                    },
                                    title: 'Да',
                                    active: true,
                                },
                                {
                                    onClick: () => dispatch(closeModal()),
                                    title: 'Нет',
                                    active: false,
                                },
                            ],
                        },
                    }),
                )
                return
            }

            resetBooking()
        }
    }, [blocker.state])
}
