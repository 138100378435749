import { useQuery } from "@tanstack/react-query";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { getNomenclatureSingle } from "../api/getNomenclatureSingle";
import { patchNomenclature } from "../api/patchNomenclature";
import { WrapperAvatar } from "./style";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { Avatar } from "shared/components/Avatar";
import { StyledLine } from "shared/styles/GlobalStyle";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { FormData, FormDataEquipment } from "../model/formData";
import { getInitialValues } from "../model/getInitialValues";
import { ValidateSchemaCreate } from "../model/validateSchema";
import { parseTimeRange } from "../utils/parseTimeRange";

interface IPropsNomenclatureProfile {
    isEditable: boolean;
    formikRef: React.Ref<FormikHelpers<any>>;
    setIsEditable: (e: boolean) => void;
    setEditButtonText: (e: string) => void;
    setType: (e: number) => void;
    setDisabled: (e: boolean) => void;
}

export const NomenclatureProfile = ({
    isEditable,
    formikRef,
    setIsEditable,
    setEditButtonText,
    setType,
    setDisabled
}:IPropsNomenclatureProfile) => {
    const {id} = useParams();
    const [photo, setPhoto] = useState<File | null>(null);

    const dispatch = useDispatch();
    const { handleFileUpload } = useFileUploadUp();
    
    const {isLoading, isError, data, isSuccess, refetch} = useQuery({
        queryKey: ["getNomenclatureSingle", id],
        queryFn: () => getNomenclatureSingle(id),
        enabled: !!id,
        refetchOnWindowFocus: false
    });

    const handlePhotoChange = (photo: File) => {
        setPhoto(photo);
    };

    const onSubmit = async(values: any) => {
        const { uploadedPhotoInfo } = await handleFileUpload(photo, []);
        const { ad_price_info } = values;
        const morningHours = ad_price_info && ad_price_info.morning_hours ? parseTimeRange(ad_price_info.morning_hours) : '';
        const eveningHours = ad_price_info && ad_price_info.evening_hours ? parseTimeRange(ad_price_info.evening_hours) : '';
        const updatedValues = {
            ...values,
            type: data?.type,
            ad_price_info: {
                ...ad_price_info,
                morning_hours: morningHours,
                evening_hours: eveningHours,
            },
        };
        if (uploadedPhotoInfo) {
            updatedValues.profile_photo_hash_name = uploadedPhotoInfo.hash_name;
        }

        console.log(updatedValues);

        patchNomenclature(
            updatedValues as any,
            refetch,
            setIsEditable,
            setEditButtonText,
            dispatch,
            id
        ).then((data) => {
            console.log(data);
        })
    }

    useEffect(() => {
        data && setType(data.type)
    }, [data]);

    return (
        <>
            {!isLoading && data &&
                <Formik
                    innerRef={formikRef as any}
                    initialValues={getInitialValues(data)}
                    onSubmit={onSubmit}
                    validationSchema={ValidateSchemaCreate}
                >
                    {({ isValid }) => {
                        setDisabled(!isValid);
                        return (
                            <Form>
                                <WrapperAvatar>
                                    {isEditable ? (
                                        <UploadAvatar 
                                            initialPhoto={data?.photo ? data?.photo?.read_url : null}
                                            onPhotoChange={handlePhotoChange}
                                        />
                                    ) : (
                                        <Avatar photo={data?.photo ? data?.photo?.read_url : null} name={data?.name}
                                        jobs={`#${data?.id} ${data?.type === 0 ? "Оборудование" : 'Расходные материалы'}`} />
                                    )}
                                </WrapperAvatar>
                                <StyledLine margin="20px 0"/>
                                {data?.type === 0 ? (
                                    <WrapperFormRow 
                                        title="Общие данные" 
                                        formData={FormDataEquipment.map(row => 
                                            row.map(item => ({ 
                                                ...item, 
                                                disabled: item.name === "created_at" ? true : !isEditable,
                                                placeholder: isEditable ? item.placeholder : "",
                                            })
                                        ))}  
                                    />
                                ) : (
                                    <WrapperFormRow 
                                        title="Общие данные" 
                                        formData={FormData.map(row => 
                                            row.map(item => ({ 
                                                ...item, 
                                                disabled: item.name === "created_at" ? true : !isEditable,
                                                placeholder: isEditable ? item.placeholder : ""
                                            })
                                        ))} 
                                    />
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            }
        </>
    )
}