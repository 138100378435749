import { axiosApi } from 'shared/api/axios'
import { adaptAddNomenclaturesRequest } from '../model/adapters/adaptAddNomenclaturesRequest'
import { adaptAddNomenclaturesResponseDTO } from '../model/adapters/adaptAddNomenclaturesResponseDTO'
import { AddNomenclaturesResponseDTO } from '../model/dtoTypes'
import { AddNomenclaturesRequest } from '../model'
import { BookingEndpoint } from './constants'

export const addNomenclaturesToBooking = async (
    body: AddNomenclaturesRequest,
) => {
    try {
        const { booking_id, ...restBody } = adaptAddNomenclaturesRequest(body)
        const response = await axiosApi.post<AddNomenclaturesResponseDTO>(
            `${BookingEndpoint.nomenclatureWithoutBackSlash}?booking_id=${booking_id}`,
            restBody,
        )
        return adaptAddNomenclaturesResponseDTO(response.data)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
