import { axiosApi } from "shared/api/axios";
import { showNotification } from "shared/redux/slice/notificationSlice";

export interface ApiResponse {
    status: number;
    data?: any;
    error?: string;

}

export const patchAreas = async (
    values: object,
    refetch: () => void,
    setIsEditable: any,
    setEditButtonText: any,
    dispatch: any,
    id: any,
): Promise<ApiResponse> => {
    try {
        const response = await axiosApi.patch(`/api/v1/areas/area/${id}`, values);
        if(response.status !== 204) {
            setIsEditable(true);
            setEditButtonText("Сохранить");
        } else {
            refetch();
            setIsEditable(false);
            setEditButtonText("Редактировать");
        }
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error: any) {
        dispatch(showNotification({message: "Ошибка", type: "error"}))
        setIsEditable(true);
        setEditButtonText("Сохранить");
        console.error("Ошибка сохранения данных:", error);
        return {
            status: error.response?.status || 500,
            error: error.message,
        };
    }
}