import { DashboardLayout } from 'app/layout'
import { ReactBigCalendar } from 'features/ReactBigCalendar'
import {
    useCalendarController,
    ViewMode,
} from 'features/BookingCalendarController/model'
import { Button } from 'shared/components/Button'
import { TabLink } from 'shared/components/TabLink'
import { linksCalendar } from 'shared/consts/linksCalendar'
import { PeriodSelector } from 'shared/components/PeriodSelector'

export const CalendarMonthPage = () => {
    const {
        currentDate,
        bookingEvents,

        onSelectSlot,
        onDropSlot,
        onClickEvent,
        onEventResize,

        handleDateChange,
        onNewBookingClick,
    } = useCalendarController({ view: ViewMode.Month })

    const views = {
        month: true,
    }

    return (
        <DashboardLayout
            header={
                <div className='dashboard__header'>
                    <TabLink list={linksCalendar} />
                    <PeriodSelector
                        period='month'
                        onDateChange={handleDateChange}
                    />
                    <div className='dashboard__right-menu'>
                        <Button onClick={onNewBookingClick}>
                            + Новая бронь
                        </Button>
                    </div>
                </div>
            }
        >
            <ReactBigCalendar
                views={views}
                defaultView='month'
                currentDate={currentDate}
                onDateChange={handleDateChange}
                events={bookingEvents}
                onSelectSlot={onSelectSlot}
                onDropSlot={onDropSlot}
                onEventResize={onEventResize}
                onClickEvent={onClickEvent}
            />
        </DashboardLayout>
    )
}
