import moment from "moment";

export const NameUserData = [
    [
        {
            name: "user_ad_personal_info.first_name",
            label: "Имя", 
            placeholder: "Иван", 
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            disabled: true,
            required: true,
        },
        { 
            name: "user_ad_personal_info.surname", 
            label: "Фамилия", 
            placeholder: "Иванов", 
            type: "text",
            disabled: true,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            required: true,
        },
        { 
            name: "user_ad_personal_info.patronymic", 
            label: "Отчество", 
            placeholder: "Иванович", 
            type: "text",
            disabled: true,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
        },
    ]
];


export const GeneralData = [
    [
        { 
            name: "phone", 
            label: "Телефон", 
            placeholder: "+7-999-999-9999", 
            maskConfig: { mask: '+{7}-000-000-00-00' }, 
            disabled: true, 
            type: "text",
            required: true
        },
        { 
            name: "email", 
            label: "Почта", 
            type: "email",
            disabled: true,
            placeholder: "Ivanov.Ivan@gmail.com",
            required: true
        },
        { 
            name: "created_at", 
            label: "Дата регистрации",
            disabled: true,
            placeholder: "18.08.2023", 
            type: "text" 
        },
        { 
            name: "role", 
            label: "Роль", 
            placeholder: "Укажите роль",
            type: "text",
            disabled: true
        },
    ],
];