import { getBookings } from './getBookings'
import { updateBookingNomenclature } from './updateBookingNomenclature'
import { updateBookings } from './updateBooking'
import { deleteNomenclaturesFromBooking } from './deleteNomenclatureFromBooking'
import { addNomenclaturesToBooking } from './addNomenclaturesToBooking'

export const BookingsApi = {
    getBookings,
    updateBookingNomenclature,
    updateBookings,
    deleteNomenclaturesFromBooking,
    addNomenclaturesToBooking,
}
