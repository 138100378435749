import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"
import { AreasTabs } from "features/AreasTabs"
import { useNavigate, useParams } from "react-router-dom"
import { AREASCREATE, AREASLIST } from "shared/consts/routingConsts"
import { AreasProfile } from "features/Areas/ui/AreasProfile"
import { axiosApi } from "shared/api/axios"
import { closeModal } from "shared/redux/slice/modalSlice"
import { useRef, useState } from "react"
import { FormikHelpers } from "formik"
import { useDispatch } from "react-redux"

export const AreasProfilePages = () => {
    const {id} = useParams();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };


    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                    {isEditable ? (
                        <>
                            <Button themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                            <Button themeType={"outline-2"} onClick={toggleEdit}>Отменить</Button>
                        </>
                    ) : (
                        <>
                            <Button themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                            <Button onClick={() => navigate(AREASCREATE)}>+Добавить зал</Button>
                        </>
                    )}
                </div>
            </div>
        }>
            <AreasTabs />
            <AreasProfile
                isEditable={isEditable}
                formikRef={formikRef}
                setIsEditable={setIsEditable}
                setEditButtonText={setEditButtonText}
            />
        </DashboardLayout>
    )
}