import { DashboardLayout } from "app/layout"
import { StyledItem } from "./style"
import { BackButton } from "shared/components/BackButton"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "shared/redux/slice/modalSlice";
import { Button } from "shared/components/Button";
import { EmployeesCreate } from "features/Employees";
import { useEffect, useRef, useState } from "react";
import { FormikHelpers } from "formik";
import { EMPLOYEESLIST } from "shared/consts/routingConsts";

export const EmployeesCreatePages = () => {
    const formikRef = useRef<FormikHelpers<any>>(null);
    
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (to: string) => {
        navigate(to);
    }

    const handleSaveClick = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const onDisabled = (disabled: boolean) => {
        setDisabled(disabled)
    }

    const handleCancelCreate = () => {
        dispatch(closeModal());
        navigate(EMPLOYEESLIST);
    }

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton
                        text="Назад"
                        onClick={() => handleClick(EMPLOYEESLIST)}
                    />
                    <div className="dashboard__header-title">Создание профиля</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                    <Button bgLoading="white" loading={loading} onClick={handleSaveClick} disabled={disabled}>Сохранить</Button>
                    <Button 
                        themeType="outline-2" 
                        onClick={() => dispatch(openModal({
                            isOpen: true,
                            content: (
                                <>Вы точно хотите отменить создание?</>
                            ),
                            config: {
                                title: "Вы точно хотите отменить?",
                                btns: [
                                    {
                                        onClick: () => handleCancelCreate(),
                                        title: "Да",
                                        active: true
                                    },
                                    {
                                        onClick: () => dispatch(closeModal()),
                                        title: "Нет",
                                        active: false
                                    }
                                ]
                            }
                        }))}
                    >Отменить</Button>
                </div>
            </div>
        }>
            <EmployeesCreate
                formikRef={formikRef}
                setDisabled={onDisabled}
                setLoading={setLoading}
            />
        </DashboardLayout>
    )
}