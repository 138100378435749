import { Form, Formik, FormikHelpers } from "formik";
import { Tab, Tabs } from "shared/components/Tabs"
import { TabsWrapper } from "./style";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { StyledLine } from "shared/styles/GlobalStyle";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { initialValues, initialValuesEquipment } from "../model/initialValues";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { FormData, FormDataEquipment } from "../model/formData";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { postNomenclature } from "../api/postNomenclature";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { NOMENCLATUREPROFILE } from "shared/consts/routingConsts";
import { ValidateSchemaCreate } from "../model/validateSchema";
import { parseTimeRange } from "../utils/parseTimeRange";
import { removeEmptyFields } from "shared/utils/removeEmptyFields";

interface IPropsNomenclatureCreate {
    setActiveTab: (e: number) => void;
    setDisabled: (e: boolean) => void;
    setLoading: (e: boolean) => void;
    formikRef: React.Ref<FormikHelpers<any>>;
    activeTab: number;
}

export const NomenclatureCreate = ({
    setActiveTab,
    setDisabled,
    setLoading,
    formikRef,
    activeTab
}: IPropsNomenclatureCreate) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [photo, setPhoto] = useState<File | null>(null);

    const { handleFileUpload } = useFileUploadUp();

    const handlePhotoChange = (photo: File) => {
        setPhoto(photo);
    };
    
    const handleSubmit = async (values: any, actions: any) => {
        const { ad_price_info } = values;
        const morningHours = ad_price_info && ad_price_info.morning_hours ? parseTimeRange(ad_price_info.morning_hours) : '';
        const eveningHours = ad_price_info && ad_price_info.evening_hours ? parseTimeRange(ad_price_info.evening_hours) : '';

        setLoading(true);
        try {
            const { uploadedPhotoInfo } = await handleFileUpload(photo, []);

            const filteredValues = removeEmptyFields({
                ...values,
                type: activeTab,
                ad_price_info: {
                    ...ad_price_info,
                    morning_hours: morningHours,
                    evening_hours: eveningHours,
                },
                photo_hash_name: uploadedPhotoInfo ? uploadedPhotoInfo.hash_name : null,
            });
            
            const response = await postNomenclature(filteredValues);

            if (response.status !== 201) {
                dispatch(showNotification({
                    message: "Ошибка",
                    type: "error"
                }));
                return;
            }

            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));

            navigate(`${NOMENCLATUREPROFILE}/${response.data.id}`);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <TabsWrapper>
            <Tabs onTabChange={setActiveTab}>
                <Tab label="Оборудование">
                    <Formik
                        innerRef={formikRef as any}
                        onSubmit={handleSubmit}
                        initialValues={{}}
                        validationSchema={ValidateSchemaCreate}
                    >
                        {({ isValid, dirty }) => {

                            setDisabled(!(isValid && dirty));
                            return (
                                <Form>
                                    <UploadAvatar 
                                        initialPhoto={null}
                                        onPhotoChange={handlePhotoChange}
                                    />
                                    <StyledLine margin="20px 0"/>
                                    <WrapperFormRow title="Оборудование" formData={FormDataEquipment} />
                                </Form>
                            )
                        }}
                    </Formik>
                </Tab>
                <Tab label="Расходные материалы">
                    <Formik
                        innerRef={formikRef as any}
                        onSubmit={handleSubmit}
                        initialValues={{}}
                        validationSchema={ValidateSchemaCreate}
                    >
                        {({ isValid, dirty }) => {
                            setDisabled(!(isValid && dirty));
                            return (
                                <Form>
                                    <UploadAvatar 
                                        initialPhoto={null}
                                        onPhotoChange={handlePhotoChange}
                                    />
                                    <StyledLine margin="20px 0"/>
                                    <WrapperFormRow title="Материалы" formData={FormData} />
                                </Form>
                            )
                        }}
                    </Formik>
                </Tab>
            </Tabs>
        </TabsWrapper>
    )
}