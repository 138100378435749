import React from 'react'
import { Nomenclature as OrderNomenclature } from '../../orders/model'
import {
    NomenclatureDiscountLabel,
    NomenclatureLabelContainer,
    NomenclatureOldPriceLabel,
} from './styles'

export const NomenclatureLabel = ({
    nomenclature,
}: {
    nomenclature: OrderNomenclature
}) => {
    const isPercentDiscount =
        nomenclature.percentDiscount && nomenclature.percentDiscount > 0
    const isAbsolutDiscount =
        nomenclature.absolutDiscount && nomenclature.absolutDiscount > 0
    const percentDiscount = nomenclature.percentDiscount
    const absolutDiscount = nomenclature.absolutDiscount

    const discountLabel = isPercentDiscount
        ? `-${percentDiscount}%`
        : isAbsolutDiscount
          ? `-${absolutDiscount}₽`
          : ''

    const roundNumber = (num: number) => Math.round(num * 100) / 100

    const name = nomenclature.name
    const count = nomenclature.count > 1 ? `(${nomenclature.count}) ₽` : ' ₽'
    const price = nomenclature.newPrice
        ? roundNumber(nomenclature.newPrice)
        : roundNumber(nomenclature.oldPrice)
    const space = ' '

    return (
        <NomenclatureLabelContainer>
            {name}
            {space}
            {Boolean(discountLabel.length) && (
                <NomenclatureDiscountLabel>
                    ({discountLabel})
                </NomenclatureDiscountLabel>
            )}
            {count}
            {price}
            {space}
            {Boolean(nomenclature.newPrice) && (
                <NomenclatureOldPriceLabel>
                    {roundNumber(nomenclature.newPrice)}
                </NomenclatureOldPriceLabel>
            )}
        </NomenclatureLabelContainer>
    )
}
