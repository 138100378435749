import { useState } from "react"; 
import { ChartLineButton, ChartLineInfoBlock, ChartLineStyled, ChartLineTitle } from "./style" 
import Chart from "react-apexcharts"; 
 
interface IPropsChartLine { 
    labels: string[] | null; 
    dataAccepted: number[]; 
    dataCancelled: number[]; 
} 
 
export const ChartLine = ({ 
    labels,  
    dataAccepted,  
    dataCancelled, 
}: IPropsChartLine) => { 
    const [visibleSeries, setVisibleSeries] = useState({ 
        accepted: true, 
        cancelled: true, 
    }); 
 
    const toggleSeries = (seriesName: 'accepted' | 'cancelled') => { 
        setVisibleSeries((prevState) => ({ 
        ...prevState, 
        [seriesName]: !prevState[seriesName], 
        })); 
    }; 

    const options: ApexCharts.ApexOptions = { 
        chart: { 
            type: 'line', 
            toolbar: { 
                show: false 
            } 
        }, 
        grid: { 
            show: false 
        }, 
        stroke: { 
            curve: 'smooth', 
            width: 2, 
            dashArray: [0, 5], 
        }, 
        xaxis: { 
            categories: labels, 
            labels: { 
                style: { 
                    colors: "rgba(28, 28, 28, 0.4)", 
                    fontSize: "12px" 
                } 
            }, 
            axisBorder: { 
                show: false 
            } 
        }, 
        yaxis: { 
            min: 0, 
            // max: 300, 
            labels: { 
                style: { 
                    colors: "rgba(28, 28, 28, 0.4)", 
                    fontSize: "12px" 
                } 
            }, 
            axisBorder: { 
                show: false, 
            }, 
            axisTicks: { 
                show: false, 
            }, 
        }, 
        legend: { 
            show: false 
        }, 
        colors: ['#5E81F4', '#A8C5DA'], 
        markers: { 
            size: 0, 
        }, 
    }; 
    
    const series = [ 
        { 
            name: 'Принято', 
            data: visibleSeries.accepted ? dataAccepted : [],
            show: visibleSeries.accepted
        }, 
        { 
            name: 'Отменено', 
            data: visibleSeries.cancelled ? dataCancelled : [],
            show: visibleSeries.cancelled,
        }, 
    ]; 

    return ( 
        <ChartLineStyled> 
            <ChartLineInfoBlock> 
                <ChartLineTitle>Количество заявок</ChartLineTitle> 
                <ChartLineButton onClick={() => toggleSeries('accepted')} bgColor={visibleSeries.accepted ? "#5E81F4" : "#e7e7e7"}>Принято</ChartLineButton> 
                <ChartLineButton onClick={() => toggleSeries('cancelled')} bgColor={visibleSeries.cancelled ? "#A8C5DA" : "#e7e7e7"}>Отменено</ChartLineButton> 
            </ChartLineInfoBlock> 
            <Chart 
                options={options} 
                series={series} 
                type="line" 
                height={244} 
            /> 
        </ChartLineStyled> 
    ) 
}