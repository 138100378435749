import { RootState } from 'shared/redux/store'

export const selectBookings = (state: RootState) =>
    state.bookingSidebarForm.bookings

export const selectActiveBookingId = (state: RootState) =>
    state.bookingSidebarForm.activeBookingId

export const selectBookingCurrentStep = (state: RootState) =>
    state.bookingSidebarForm.currentStep

export const selectBookingMode = (state: RootState) =>
    state.bookingSidebarForm.mode

export const selectBookingCounterpartyId = (state: RootState) =>
    state.bookingSidebarForm.counterpartyId

export const selectBookingOrderId = (state: RootState) =>
    state.bookingSidebarForm.orderId

export const selectBookingFormMode = (state: RootState) =>
    state.bookingSidebarForm.mode

export const selectBookingOriginalOrder = (state: RootState) =>
    state.bookingSidebarForm.originalOrder

export const selectActiveBookingFormTab = (state: RootState) =>
    state.bookingSidebarForm.bookingActiveTab

export const selectOrderEditStateInitialising = (state: RootState) =>
    state.bookingSidebarForm.isEditStateInitialising

export const selectOrderTotalPrice = (state: RootState) =>
    state.bookingSidebarForm.totalPrice
