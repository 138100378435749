import { useQuery } from "@tanstack/react-query";
import { getInitialValues } from "features/Nomenclature/model/getInitialValues";
import { FormikHelpers, Formik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, useParams } from "react-router-dom";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { getAreasSingle } from "../api/getAreasSingle";
import { patchAreas } from "../api/patchAreas";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { GeneralData } from "../model/generalData";

interface IPropsFile {
    id: number;
    name: string;
    hash_name?: string;
    read_url?: string;
}

interface IPropsEmployeesProfile {
    isEditable: boolean;
    formikRef: React.Ref<FormikHelpers<any>>;
    setIsEditable: (e: boolean) => void;
    setEditButtonText: (e: string) => void;
}

export const AreasProfile = ({
    isEditable,
    formikRef,
    setIsEditable,
    setEditButtonText
}:IPropsEmployeesProfile) => {
    const {id} = useParams();

    const dispatch = useDispatch();

    const {isLoading, isError, data, isSuccess, refetch} = useQuery({
        queryKey: ["getProfile"],
        queryFn: () => getAreasSingle(id),
        enabled: !!id,
        refetchOnWindowFocus: false
    });

    const onSubmit = async(values: any) => {
        const updatedValues = {
            ...values
        };

        patchAreas(
            updatedValues as any,
            refetch,
            setIsEditable,
            setEditButtonText,
            dispatch,
            id
        ).then((data) => {
            console.log(data);
        })
    }    

    return (
        <>
            {!isLoading && data &&
                <Formik
                    innerRef={formikRef as any}
                    initialValues={getInitialValues(data)}
                    onSubmit={onSubmit}
                >
                    <>
                    <WrapperFormRow title="Общие данные" formData={GeneralData.map(row => row.map(item => ({ ...item, disabled: !isEditable })))} />
                    </>
                </Formik>
            }
        </>
    )
}