import React, { FC } from 'react'
import { InputContainer, Label } from 'shared/components/Input/style'
import { StyledSelect } from './styles'

export type AutocompleteOption = {
    value: string
    label: string
    payload?: object
}

export interface AutocompleteProps {
    options: AutocompleteOption[]
    onChange: (option: AutocompleteOption) => void
    value?: AutocompleteOption | null
    label?: string
    placeholder?: string
    disabled?: boolean
    required?: boolean

    onCreateOption?: (text: string) => void
    onLoadMore?: () => void
    onInputChange?: (text: string) => void
    inputValue?: any
    isLoading?: any
}

export const Autocomplete: FC<AutocompleteProps> = ({
    value,
    options,
    onChange,
    placeholder,
    disabled,
    label,
    required,

    onLoadMore,
    onInputChange,
    isLoading,
    onCreateOption,
}) => {
    return (
        <InputContainer>
            {label && (
                <Label>
                    {required && <span className='required'>*</span>}
                    {label}
                </Label>
            )}
            <StyledSelect
                classNames={{
                    control: () => 'control',
                    input: () => 'input',
                    placeholder: () => 'placeholder',
                }}
                value={value}
                onChange={onChange as any}
                options={options}
                isClearable
                placeholder={placeholder}
                isDisabled={disabled}
                onMenuScrollToBottom={onLoadMore}
                onInputChange={onInputChange}
                isLoading={isLoading}
                onCreateOption={onCreateOption}
            />
        </InputContainer>
    )
}
