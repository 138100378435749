import { DetailOrderDTO } from '../dtoTypes'
import { DetailOrder } from '../types'
import { adaptOrderDTO } from './adaptOrderDTO'
import { adaptBookingDTO } from './adaptBookingDTO'

export const adaptDetailOrderDTO = ({
    bookings,
    ...order
}: DetailOrderDTO): DetailOrder => ({
    ...adaptOrderDTO(order),
    counterpartyPhone: order.counterparty_phone,
    counterpartyEmail: order.counterparty_email,
    counterpartyDiscount: order.counterparty_discount,
    bookings: bookings.map(adaptBookingDTO),
})
