import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { OrdersApi } from 'entities/orders/api'
import {
    BookingMode,
    BookingStep,
    selectBookingMode,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    setBookingCounterpartyId,
    setBookingCurrentStep,
    setBookingOrderId,
} from '../slices'
import { useCounterpartiesOptions } from './useGeneralInfoCounterpartiesHelpers'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'
import { CLIENTSCREATE } from 'shared/consts/routingConsts'

import { DynamicCreationReducersType } from 'pages/clientsCreate/model/dynamicCreationReducers'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { showNotification } from 'shared/redux/slice/notificationSlice'

export const useGeneralInfoController = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const orderId = useSelector(selectBookingOrderId)
    const originalOrder = useSelector(selectBookingOriginalOrder)

    const mode = useSelector(selectBookingMode)
    const isCreate = useMemo(() => mode === BookingMode.Create, [mode])
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const [isLoading, setIsLoading] = useState(false)

    const { isOneOfTheBookingsAlreadyCreated } = useIsBookingsAlreadyCreated()

    const {
        counterpartyId,
        counterpartiesOptions,
        currentCounterpartyOption,
        currentCounterparty,
        fetchNextPage,
        isFetching,
        setSearchString,
    } = useCounterpartiesOptions()

    const isButtonDisabled = !counterpartyId

    const onCounterpartyChange = (option: AutocompleteOption) => {
        const newOption = option ? Number(option.value) : null
        dispatch(setBookingCounterpartyId({ id: newOption }))
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const onGoNext = async () => {
        if (isEdit) {
            dispatch(setBookingCurrentStep({ step: BookingStep.Booking }))
            if (!counterpartyId || !orderId || !originalOrder?.counterpartyId) {
                return
            }

            if (counterpartyId === originalOrder?.counterpartyId) {
                return
            }

            setIsLoading(true)
            await OrdersApi.updateOrder({
                orderId,
                counterpartyId: Number(counterpartyId),
            })
            setIsLoading(false)
            showSuccessAlert()
            return
        }

        if (!counterpartyId) {
            return
        }

        dispatch(setBookingCurrentStep({ step: BookingStep.Booking }))

        if (isOneOfTheBookingsAlreadyCreated && orderId) {
            setIsLoading(true)
            await OrdersApi.updateOrder({
                orderId,
                counterpartyId: Number(counterpartyId),
            })
            setIsLoading(false)
            showSuccessAlert()
            return
        }

        setIsLoading(true)
        const result = await OrdersApi.createOrder({
            counterpartyId: Number(counterpartyId),
        })
        dispatch(setBookingOrderId({ orderId: result.orderId }))
        setIsLoading(false)
        showSuccessAlert()
    }

    const createCounterpartyAndChange = (phone: string) => {
        navigate(CLIENTSCREATE, {
            state: {
                initFormData: {
                    phone,
                },
                dynamicCreationReducerType: DynamicCreationReducersType.Booking,
            },
        })
    }

    return {
        isLoading,
        orderId,
        isCreate,
        isEdit,
        counterpartiesOptions,
        currentCounterpartyOption,
        currentCounterparty,
        isButtonDisabled,
        isOneOfTheBookingsAlreadyCreated,
        fetchNextPage,
        isFetching,
        setSearchString,

        onGoNext,
        onCounterpartyChange,
        createCounterpartyAndChange,
    }
}
