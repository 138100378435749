import { OrderBookingDTO } from '../dtoTypes'
import { OrderBooking } from '../types'
import { adaptNomenclatureDTO } from './adaptNomenclatureDTO'

export const adaptBookingDTO = (bookingDTO: OrderBookingDTO): OrderBooking => ({
    id: bookingDTO.id,
    areaId: bookingDTO.area_id,
    areaName: bookingDTO.area_name,
    membersCount: bookingDTO?.members_count ?? null,
    comment: bookingDTO.comment,
    endTime: new Date(bookingDTO.end_time),
    startTime: new Date(bookingDTO.start_time),
    areaPrice: parseFloat(bookingDTO.area_price),
    nomenclaturesPrice: parseFloat(bookingDTO.nomenclatures_price),
    nomenclatures: bookingDTO.nomenclatures.map(adaptNomenclatureDTO),
})
