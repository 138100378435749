import {
    useCounterpartiesQuery,
    useCounterpartyAsyncAutocompleteOptions,
} from 'entities/counterparties/model'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { selectBookingCounterpartyId } from '../slices'

export const useCounterpartiesOptions = () => {
    const [searchString, setSearchString] = useState<string>()
    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const { counterparties, fetchNextPage, isFetching } =
        useCounterpartyAsyncAutocompleteOptions({ search_param: searchString })

    const counterpartiesOptions: AutocompleteOption[] = useMemo(
        () =>
            counterparties?.length
                ? counterparties.map(counterparty => ({
                      value: counterparty.id.toString(),
                      label: counterparty.contact.phone ?? counterparty.phone,
                      payload: {
                          surname: counterparty?.contact?.surname,
                          firstName: counterparty?.contact?.firstName,
                          patronymic: counterparty?.contact?.patronymic,
                      },
                  }))
                : [],
        [counterparties],
    )

    const currentCounterpartyOption = counterpartiesOptions?.find(
        option => option.value === counterpartyId?.toString(),
    )

    const currentCounterpartyObject = counterparties?.find(
        counterparty =>
            counterparty.id.toString() === counterpartyId?.toString(),
    )
    const currentCounterpartyEmail =
        currentCounterpartyObject?.contact?.email ??
        currentCounterpartyObject?.email
    const currentCounterpartyName = [
        currentCounterpartyObject?.contact?.surname,
        currentCounterpartyObject?.contact?.firstName,
        currentCounterpartyObject?.contact?.patronymic,
    ].join(' ')

    const currentCounterparty = {
        email: currentCounterpartyEmail,
        name: currentCounterpartyName,
    }

    return {
        counterpartyId,
        counterpartiesOptions,
        currentCounterpartyOption,
        currentCounterparty,
        fetchNextPage,
        isFetching,
        setSearchString,
    }
}
