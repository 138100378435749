import { CounterpartyDto } from '../dtoTypes'
import { Counterparty } from '../types'

export const adaptCounterpartiesDTO = (dto: CounterpartyDto): Counterparty => ({
    id: dto.id,
    type: dto.type,
    phone: dto.phone,
    email: dto.email,
    profilePhoto: {
        hashName: dto?.profile_photo?.hash_name ?? null,
        readUrl: dto?.profile_photo?.read_url ?? null,
    },
    contact: {
        surname: dto?.contact?.surname ?? null,
        firstName: dto?.contact?.first_name ?? null,
        patronymic: dto?.contact?.patronymic ?? null,
        phone: dto?.contact?.phone ?? null,
        email: dto?.contact?.email ?? null,
    },
    isBlackList: dto.is_black_list,
    comment: dto.comment,
    bookingHours: dto.booking_hours,
    additionalData: {
        documents: {
            serial: dto?.additional_data?.documents?.serial ?? null,
            divisionCode:
                dto?.additional_data?.documents?.division_code ?? null,
            issuedBy: dto?.additional_data?.documents?.issued_by ?? null,
            issueDate: dto?.additional_data?.documents?.issue_date ?? null,
            city: dto?.additional_data?.documents?.city ?? null,
            street: dto?.additional_data?.documents?.street ?? null,
            houseNumber: dto?.additional_data?.documents?.house_number ?? null,
            apartmentNumber:
                dto?.additional_data?.documents?.apartment_number ?? null,
            index: dto?.additional_data?.documents?.index ?? null,
        },
    },
})
