import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { AREASPROFILE } from "shared/consts/routingConsts"
import { useEffect } from "react"
import { getAreas } from "../api/getAreas"
import { HallsListWrapper } from "./style"

export const AreasTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const { isLoading, isError, data} = useQuery({
        queryKey: ["getAreas"], 
        queryFn: () => {
            return getAreas();
        },
        placeholderData: keepPreviousData,
        retry: 3,
    });

    useEffect(() => {
        if (data && data.areas.length > 0 && location.pathname === "/areas") {
            navigate(`${AREASPROFILE}/${data.areas[0].id}`);
        }
    }, [data, navigate, location.pathname]); 

    return (
        <>
            <HallsListWrapper>
                <div className="halls__list">
                    {data && data.areas.map((item, index) => (
                        <NavLink key={index} to={`${AREASPROFILE}/${item.id}`}>
                            {item.name}
                        </NavLink>
                    ))}
                </div>
            </HallsListWrapper>
        </>
    )
}