import { FormEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersApi } from 'entities/orders/api'
import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { OrderBooking } from 'entities/orders/model'
import { BookingsApi } from 'entities/bookings/api'
import { OrderNomenclatureToAdd } from 'entities/bookings/ui'

import { showNotification } from 'shared/redux/slice/notificationSlice'

import { useBookingFormDateTimeHelper } from './useBookingFormDateTimeHelper'
import {
    ActiveBookingFormTab,
    addEmptyBooking,
    addNomenclatureToBooking,
    BookingMode,
    BookingStep,
    editBooking,
    OrderBookingWithOptionsFields,
    removeBooking,
    removeNomenclatureToBooking,
    replaceBookingTempClientIdToOriginId,
    selectActiveBookingFormTab,
    selectActiveBookingId,
    selectBookingMode,
    selectBookingOrderId,
    selectBookings,
    setActiveBookingId,
    setBookingActiveTab,
    setBookingCurrentStep,
    setMembersCountToBooking,
} from '../slices'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'
import { useFillStore } from './useFillStore'

export const useBookingFormController = () => {
    const dispatch = useDispatch()
    const orderId = useSelector(selectBookingOrderId)
    const updateBookings = useFillStore()

    const mode = useSelector(selectBookingMode)
    const isCreate = useMemo(() => mode === BookingMode.Create, [mode])
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])

    const {
        checkIsBookingAlreadyCreated,
        isActiveBookingAlreadyCreated,
        refetchAlreadyCreatedBookings,
    } = useIsBookingsAlreadyCreated()

    const activeBookingId = useSelector(selectActiveBookingId)
    const bookings = useSelector(selectBookings)
    const activeBooking = useMemo(
        () => bookings.find(a => a.id === activeBookingId),
        [bookings, activeBookingId],
    )

    const {
        start,
        end,

        onEditStartDate,
        onEditEndDate,
        onEditStartTime,
        onEditEndTime,
    } = useBookingFormDateTimeHelper(activeBooking)

    const { areasResources } = useAreasResourcesQuery()

    const activeTab = useSelector(selectActiveBookingFormTab)
    const setActiveTab = (newActiveTab: ActiveBookingFormTab) => {
        dispatch(setBookingActiveTab({ tab: newActiveTab }))
    }

    const isCreateDisabledTab =
        activeTab === ActiveBookingFormTab.Booking
            ? checkIsBookingAlreadyCreated(activeBooking)
                ? undefined
                : ActiveBookingFormTab.Nomenclatures
            : undefined

    const isEditDisabledTab = isActiveBookingAlreadyCreated
        ? undefined
        : isCreateDisabledTab

    const disabledTab = isCreate ? isCreateDisabledTab : isEditDisabledTab

    const membersCount = useMemo(
        () => activeBooking?.membersCount ?? 0,
        [activeBooking],
    )

    const isButtonDisabled =
        activeTab === ActiveBookingFormTab.Booking &&
        (membersCount <= 0 ||
            !orderId ||
            bookings.some(
                booking =>
                    !booking.areaId || !booking?.startTime || !booking?.endTime,
            ))

    const onEditBooking = (
        bookingId: number,
        body: OrderBookingWithOptionsFields,
    ) => {
        dispatch(editBooking({ bookingId, body }))
    }

    const onAddBooking = () => {
        const bookingId = new Date().getMilliseconds() ** 2
        dispatch(addEmptyBooking({ bookingId }))
        dispatch(setActiveBookingId({ bookingId }))
        setActiveTab(ActiveBookingFormTab.Booking)
    }

    const onRemoveBooking = async (bookingId: number) => {
        if (isCreate) {
            return dispatch(removeBooking({ bookingId }))
        }
        if (isEdit) {
            if (!orderId) {
                return
            }

            const isConfirm = confirm(
                'Вы точно хотите удалить это бронирование?',
            )
            if (!isConfirm) {
                return
            }
            await OrdersApi.deleteBookingFromOrder({
                orderId,
                bookingId,
            })
        }
    }

    const onChangeActiveBookingId = (bookingId: number) => {
        dispatch(setActiveBookingId({ bookingId }))
    }

    const onEditMembersCount = (e: FormEvent) => {
        if (activeBooking) {
            const newMembersCount = Number((e.target as HTMLInputElement).value)
            dispatch(
                setMembersCountToBooking({
                    bookingId: activeBooking.id,
                    newMembersCount,
                }),
            )
        }
    }

    const onEditArea = (newAreaId: string | number) => {
        if (activeBooking) {
            const areaId = Number(newAreaId)
            const areaName =
                areasResources?.find(res => res.id === areaId)?.title ??
                'Новый зал'
            onEditBooking(activeBooking.id, { areaId, areaName })
        }
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const onAddNomenclature = async (nomenclature: OrderNomenclatureToAdd) => {
        const nomenclatureId = nomenclature.id
        const count = nomenclature.count
        const absolutDiscount = nomenclature.absolutDiscount
        const percentDiscount = nomenclature.percentDiscount

        if (orderId && activeBookingId && nomenclatureId) {
            const response = await BookingsApi.addNomenclaturesToBooking({
                bookingId: activeBookingId,
                nomenclatureId: nomenclatureId,
                count,
                absolutDiscount:
                    absolutDiscount > 0 ? absolutDiscount : undefined,
                percentDiscount:
                    percentDiscount > 0 ? percentDiscount : undefined,
            })
            dispatch(
                addNomenclatureToBooking({
                    bookingId: activeBookingId,
                    nomenclature: {
                        ...nomenclature,
                        oldPrice: response.nomenclature.oldPrice,
                        newPrice: response.nomenclature.newPrice,
                    },
                }),
            )
            showSuccessAlert()
        }
    }

    const onRemoveNomenclature = (nomenclature: OrderNomenclatureToAdd) => {
        const nomenclatureId = nomenclature.id

        if (orderId && activeBookingId && nomenclatureId) {
            dispatch(
                removeNomenclatureToBooking({
                    bookingId: activeBookingId,
                    nomenclatureId,
                }),
            )
            BookingsApi.deleteNomenclaturesFromBooking({
                orderId,
                bookingId: activeBookingId,
                bookingNomenclatureId: nomenclatureId,
            })
                .then(showSuccessAlert)
                .catch(err => console.error(err))
        }
    }

    const onGoNext = async () => {
        if (!orderId) {
            return
        }

        if (activeTab === ActiveBookingFormTab.Booking) {
            await Promise.all(
                bookings.map(async booking => {
                    const alreadyCreated = checkIsBookingAlreadyCreated(booking)

                    if (alreadyCreated) {
                        return onUpdateBooking(booking)
                    }
                    return onCreateBooking(booking)
                }),
            )

            setActiveTab(ActiveBookingFormTab.Nomenclatures)
            await refetchAlreadyCreatedBookings()
            return
        }

        dispatch(setBookingCurrentStep({ step: BookingStep.Payment }))
    }

    const onCreateBooking = async (booking: OrderBooking) => {
        if (
            !booking.areaId ||
            !orderId ||
            !booking?.startTime ||
            !booking?.endTime ||
            !membersCount ||
            membersCount < 0
        ) {
            return
        }

        const newBooking = {
            orderId,
            areaId: booking.areaId,
            startTime: booking.startTime.toISOString(),
            endTime: booking.endTime.toISOString(),
            membersCount,
            comment: '',
        }
        const { bookingId, totalPrice } =
            await OrdersApi.addBookingToOrder(newBooking)
        if (activeBookingId === booking.id) {
            dispatch(setActiveBookingId({ bookingId }))
        }
        dispatch(
            replaceBookingTempClientIdToOriginId({
                oldId: booking.id,
                newId: bookingId,
            }),
        )
        await updateBookings(orderId)
        showSuccessAlert()
    }

    const onUpdateBooking = async (booking: OrderBooking) => {
        if (
            !booking.areaId ||
            !orderId ||
            !booking?.startTime ||
            !booking?.endTime ||
            !membersCount ||
            membersCount < 0
        ) {
            return
        }

        const queries = {
            orderId,
            bookingId: booking.id,
        }

        const updatedBooking = {
            areaId: booking.areaId,
            startTime: booking.startTime,
            endTime: booking.endTime,
            membersCount,
            comment: '',
        }
        await BookingsApi.updateBookings(queries, updatedBooking)
        await updateBookings(orderId)
        showSuccessAlert()
    }

    return {
        start,
        end,
        activeBooking,
        areasResources,
        activeBookingId,
        bookings,
        membersCount,
        activeTab,
        disabledTab,
        isButtonDisabled,

        setActiveTab,
        onAddNomenclature,
        onRemoveNomenclature,

        onChangeActiveBookingId,
        onAddBooking,
        onRemoveBooking,

        onEditMembersCount,
        onEditArea,
        onEditStartDate,
        onEditEndDate,
        onEditStartTime,
        onEditEndTime,

        onGoNext,
    }
}
