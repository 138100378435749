import React, { FC, FormEvent, useState } from 'react'

import { Nomenclature as OrderNomenclature } from 'entities/orders/model'
import { useNomenclaturesQuery } from 'entities/nomenclatures/model'
import { Icon } from 'shared/components/Icons'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import {
    Autocomplete,
    AutocompleteOption,
} from 'shared/components/Autocomplete'

import {
    StyledAddedNomenclature,
    StyledAddedNomenclatureContainer,
    StyledAutocompleteWrapper,
    StyledContainer,
    StyledCrossIconContainer,
    StyledNomenclatureCountInputContainer,
    StyledNomenclatureDiscountInputContainer,
    StyledNomenclatureInputContainer,
} from './styles'
import { NomenclaturesInputProps } from './types'
import { adaptNomenclaturesToOption } from './utils'
import { NomenclatureLabel } from './NomenclatureLabel'

export const NomenclaturesInput: FC<NomenclaturesInputProps> = ({
    values,
    onAddNomenclature,
    onRemoveNomenclature,
}) => {
    const [selectedValue, setSelectedValue] =
        useState<AutocompleteOption | null>()
    const [count, setCount] = useState<number>(0)
    const [absolutDiscount, setAbsolutDiscount] = useState<number>(0)
    const [percentDiscount, setPercentDiscount] = useState<number>(0)

    const { nomenclatures } = useNomenclaturesQuery()
    const options: AutocompleteOption[] =
        nomenclatures
            ?.filter(nomenclature => nomenclature.price)
            ?.map(adaptNomenclaturesToOption) ?? []

    const onChangeSelectedValue = (option: AutocompleteOption) => {
        setSelectedValue(option)
        setCount(0)
    }

    const onRemoveSelectedValue = async (option: OrderNomenclature) => {
        if (onRemoveNomenclature) {
            onRemoveNomenclature(option)
        }
    }

    const onIncreaseCount = () => {
        setCount(prev => prev + 1)
    }

    const onDecreaseCount = () => {
        setCount(prev => prev - 1)
    }

    const onAbsolutDiscountChange = (e: FormEvent) => {
        const newAbsolutDiscount = Number((e.target as HTMLInputElement).value)
        setAbsolutDiscount(newAbsolutDiscount)

        if (!selectedValue) {
            return
        }
        const newSelectedValue: AutocompleteOption = {
            ...selectedValue,
            payload: {
                ...(selectedValue?.payload ?? {}),
                absolutDiscount: newAbsolutDiscount,
            },
        }
        setSelectedValue(newSelectedValue)
        setPercentDiscount(0)
    }

    const onPercentDiscountChange = (e: FormEvent) => {
        const newPercentDiscount = Number((e.target as HTMLInputElement).value)
        setPercentDiscount(newPercentDiscount)

        if (!selectedValue) {
            return
        }

        const newSelectedValue: AutocompleteOption = {
            ...selectedValue,
            payload: {
                ...(selectedValue?.payload ?? {}),
                percentDiscount: newPercentDiscount,
            },
        }
        setSelectedValue(newSelectedValue)
        setAbsolutDiscount(0)
    }

    const onAdd = async () => {
        setCount(0)
        setAbsolutDiscount(0)
        setPercentDiscount(0)
        setSelectedValue(null)

        if (onAddNomenclature && selectedValue) {
            onAddNomenclature({
                id: Number(selectedValue.value),
                name: selectedValue.label,
                count,
                percentDiscount,
                absolutDiscount,
            })
        }
    }

    return (
        <StyledContainer>
            <StyledNomenclatureInputContainer>
                <StyledAutocompleteWrapper>
                    <Autocomplete
                        options={options}
                        onChange={onChangeSelectedValue}
                        value={selectedValue}
                        placeholder='-'
                    />
                </StyledAutocompleteWrapper>

                <StyledNomenclatureCountInputContainer>
                    <Icon onClick={onDecreaseCount} name='minus' />
                    <span>{count}</span>
                    <Icon onClick={onIncreaseCount} name='mediumPlus' />
                </StyledNomenclatureCountInputContainer>
            </StyledNomenclatureInputContainer>

            <StyledNomenclatureDiscountInputContainer>
                <Input
                    name='absolutDiscount'
                    type='number'
                    min={0}
                    value={absolutDiscount}
                    onChange={onAbsolutDiscountChange}
                    label='Скидка, ₽'
                />
                <Input
                    name='percentDiscount'
                    type='number'
                    min={0}
                    value={percentDiscount}
                    onChange={onPercentDiscountChange}
                    label='Скидка, %'
                />
                <Button onClick={onAdd} padding={8}>
                    <Icon name='addRoundedIcon' />
                </Button>
            </StyledNomenclatureDiscountInputContainer>
            <StyledAddedNomenclatureContainer>
                {(values ?? []).map(val => (
                    <StyledAddedNomenclature>
                        <NomenclatureLabel nomenclature={val} />
                        <StyledCrossIconContainer
                            onClick={() => onRemoveSelectedValue(val)}
                        >
                            <Icon name='deleteRoundedIcon' />
                        </StyledCrossIconContainer>
                    </StyledAddedNomenclature>
                ))}
            </StyledAddedNomenclatureContainer>
        </StyledContainer>
    )
}
