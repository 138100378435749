import styled from 'styled-components'

export const StyledAreaSelect = styled.select`
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    border: 1px solid #e1e4ea;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #282a42;
    &::placeholder {
        color: #cdcddf;
    }
    &:focus,
    &:active,
    &:focus-visible {
        outline: none;
    }
    &::disabled::placeholder {
        color: #282a42;
    }
`
