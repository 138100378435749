import { DashboardLayout } from "app/layout"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"
import { FallBackTitle, FallBackWrapper } from "./style"

export const Fallback = () => {
    const error = useRouteError()

    if (isRouteErrorResponse(error)) {
        console.log(error);
        return (
            <DashboardLayout>
                <FallBackWrapper>
                    <FallBackTitle>Что-то пошло не так...</FallBackTitle>
                    <h2>{error.status}</h2>
                    <p>{error.statusText}</p>
                    {error.data?.message && <p>{error.data.message}</p>}
                </FallBackWrapper>
            </DashboardLayout>
        )
    } else {
        return (
            <DashboardLayout>
                <FallBackWrapper>
                    <FallBackTitle>Упс...</FallBackTitle>
                </FallBackWrapper>
            </DashboardLayout>
        )
    }
}