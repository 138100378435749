import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Table } from "shared/components/Table";
import { WrapperOrderTable } from "./style";
import { getOrderClients } from "../api/getOrderClients";
import { columnOrderClients } from "../model/columns";

export const OrdersTable = ({
    id
}: {
    id?: string
}) => {
    const { isLoading, isError, data, refetch} = useQuery({
        queryKey: ["getOrdersClients", id], 
        queryFn: () => {
            return getOrderClients(id);
        },
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60
    });
    return (
        <>
            {!isLoading && data && (
                <WrapperOrderTable>
                    <Table 
                        className="clientsTable" 
                        data={data}
                        columns={columnOrderClients} 
                        columnWidths={['100px']}
                        isLoading={isLoading}
                        isError={isError}
                        disablePagination={true}
                        disableSelection={true}
                        disableSorting={true}
                    />
                </WrapperOrderTable>
            )}
        </>
    )
}