import styled from "styled-components";

export const BookingSidebarContainer = styled.div<{ isExpanded: boolean }>`
    background: ${props => props.theme.background.white};
    border-radius: 16px 0px 0px 16px;
    width: 100%;
    max-width: ${props => (props.isExpanded ? '386px' : '80px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s linear;
    overflow-y: scroll;
    padding: 10px 16px 10px 16px;
    &::-webkit-scrollbar {
        width: 2px; 
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(94, 129, 244, 0.50);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(94, 129, 244, 0.70);
    }
    .back {
        transition: all 0.2s linear;
    }
    .sibedar-top-row {
        padding-top: 10px;
        ${props => props.isExpanded && `
            width: 100%;
            max-width: 354px;
            display: flex;
            flex-direction: column;
            align-items: end;
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 999;
        `}
    }
    .line {
        width: ${props => (props.isExpanded ? '100%' : '39px')};
        height: 1px;
        background: #E1E4EA;
        margin: 16px 0;
    }

`;
