import { axiosApi } from 'shared/api/axios'
import { AreasEndpoint } from './constants'
import { GetAreasResponseDTO } from '../model/dtoTypes'
import { Area } from '../model/types'
import { adaptAreaDTO } from '../model/adapters/adaptAreaDTO'

export const getAreas = async ({
    limit,
}: {
    limit?: number
}): Promise<Area[]> => {
    try {
        const response = await axiosApi.get<GetAreasResponseDTO>(
            `${AreasEndpoint.area}?limit=${limit ?? 7}`,
        )
        return response.data.areas.map(adaptAreaDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка')
    }
}
