import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { NomenclaturesApi } from '../../api'
import { QueryKeys } from './constants'
import { Nomenclature } from '../types'

export const useNomenclaturesQuery = (options?: UseQueryOptions) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetAllNomenclature],
            queryFn: NomenclaturesApi.getNomenclatures,
            ...(options ?? {}),
        })

    return {
        nomenclatures: data as Nomenclature[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
