import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Input } from 'shared/components/Input'
import { Icon } from 'shared/components/Icons'
import { closeSidebar } from 'shared/redux/slice/sidebar'
import { RootState } from 'shared/redux/store'
import { useSidebar } from './components/SidebarProvider/SidebarProvider'
import { booking, notifications } from './statics'
import { CardNotifications } from './components/CardNotifications'
import { BookingSidebar } from '../Booking/ui/BookingSidebar'
import { Box, StyledBookingForm, StyledItem, StyledSidebar } from './style'

export const RightSidebar: React.FC = () => {
    const dispatch = useDispatch()
    const { isOpen, component } = useSelector(
        (state: RootState) => state.sidebar,
    )

    const handleCloseSidebar = () => {
        dispatch(closeSidebar())
    }

    const [bookingForm, setBookingForm] = useState(false)
    const { isExpanded, toggleSidebar } = useSidebar()

    if (isOpen && component === 'BookingComponent') {
        return <BookingSidebar />
    }
    if (bookingForm) {
        return (
            <Box isExpanded={isExpanded}>
                <div className='sibedar-top-row'>
                    <StyledItem onClick={() => setBookingForm(false)}>
                        <button>
                            <Icon
                                className='back'
                                name='arrowRight'
                                style={{
                                    transform: isExpanded
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                }}
                            />
                        </button>
                    </StyledItem>
                    <div className='line'></div>
                    <StyledBookingForm>
                        <div className='row-form'>
                            <Input
                                name=''
                                type='text'
                                label='Номер заказа'
                                value='12351646516'
                            />
                            <Input name='' type='text' value='Активно' />
                        </div>
                        <div>
                            <Input
                                name=''
                                type='text'
                                label='ФИО'
                                value='12351646516'
                            />
                            <div className='open'>Открыть профиль</div>
                        </div>
                        <Input
                            name=''
                            type='text'
                            label='ФИО'
                            value='12351646516'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Контактный телефон'
                            value='+7 932 234 23 43'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Контактный Email'
                            value='Ivanov.Ivan@mail.ru'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Помещение'
                            value='Темный зал'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Количество человек'
                            value='8'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Дата'
                            value='Понедельник, 28 октября 2023'
                        />
                        <div className='row-form'>
                            <Input
                                name=''
                                type='text'
                                label='Время начала'
                                value='16:00'
                            />
                            <Input
                                name=''
                                type='text'
                                label='Время конца'
                                value='21:00'
                            />
                        </div>
                        <Input
                            name=''
                            type='text'
                            label='Дополнения'
                            value='Пиво (x5); чипсы (x5)'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Способ оплаты'
                            value='Онлайн оплата'
                        />
                        <Input
                            name=''
                            type='text'
                            label='Сумма'
                            value='₽15 000'
                        />
                        <div className='row-form'>
                            <Input
                                name=''
                                type='text'
                                label='Сумма'
                                value='Оплачено'
                            />
                            <Input
                                name=''
                                type='text'
                                label='Дата'
                                value='12.10.2023, 10:30'
                            />
                        </div>
                        <Input
                            name=''
                            type='text'
                            label='Комментарий'
                            value='Возможно опоздание'
                        />
                    </StyledBookingForm>
                </div>
            </Box>
        )
    }
    return (
        <Box isExpanded={isExpanded}>
            <div className='sibedar-top-row'>
                <StyledItem onClick={toggleSidebar}>
                    <button>
                        <Icon
                            className='back'
                            name='arrowRight'
                            style={{
                                transform: isExpanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                        />
                    </button>
                </StyledItem>
                <div className='line'></div>
            </div>
            {isExpanded && (
                <StyledSidebar>
                    <div className='title'>
                        Уведомления
                        <div className='badge'>3</div>
                    </div>
                    {notifications.map((item, index) => (
                        <CardNotifications
                            setBookingForm={setBookingForm}
                            data={item}
                        />
                    ))}
                    <div className='line'></div>
                    <div className='title'>Предстоящие брони</div>
                    {booking.map((item, index) => (
                        <CardNotifications
                            setBookingForm={setBookingForm}
                            data={item}
                        />
                    ))}
                </StyledSidebar>
            )}
            {!isExpanded && (
                <>
                    <StyledItem>
                        <button>
                            <Icon name='notification' />
                        </button>
                    </StyledItem>
                </>
            )}
        </Box>
    )
}
