import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        background-color: ${props => props.theme.background.main};
        color: ${props => props.theme.colors.text};
        font-family: "Inter", sans-serif;
        /* height: calc(100vh - 20px); */
        /* padding: 10px 0; */
        iframe {
            display: none;
        }
    }
    button {
        cursor: pointer;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    input:focus-visible {
        outline: none!important;
    }

    .dropdown-block {
        border-radius: 16px;
        box-shadow: 2px 4px 14px 0px rgba(0, 0, 0, 0.12);
        width: 100%;
        max-width: 140px!important;
        background-color: #fff;
        margin-top: 5px;
        &__item {
            border-bottom: 1px solid #F6F6F6;
            padding: 16px 0;
            color: #282A42;
            font-family: "Lato", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            cursor: pointer;
            &:hover, &.active {
                color: #5E81F4;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    * {
        &:focus-visible {
            outline: none;
        }
    }

    /* CustomDatePicker */
    .rdrWeekDays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 64px;
        margin-bottom: 15px;
        .rdrWeekDay {
            color: #282A42;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.28px;
            text-transform: capitalize;
        }
    }
    .rdrDays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 64px;
        row-gap: 15px;
    }

    .editButton {
        color: #8181A5;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
    }
    .addButtonPhoto {
        color: #8181A5;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        border: 1px dashed #E1E4EA;
        border-radius: 4px;
        padding: 10px;
        width: 100%;
        cursor: pointer;
    }
`;

interface StyledLineProps {
    margin?: string;
}

export const StyledLine = styled.div<StyledLineProps>`
    display: block;
    background: #E1E4EA;
    width: 100%;
    height: 1px;
    margin: ${(props) => props.margin || '0'};
`;

export const StyledWrapperSertificate = styled.div`
    display: flex;
    gap: 10px;
    align-items: flex-end;
    label {
        font-size: 12px;
    }
    .blockSertificat {
        color: #8181A5;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 100%;
        border: 1px solid #E1E4EA;
        background: #fff;
        border-radius: 8px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .wrapperButton {
        display: flex;
        gap: 10px;
        .styledButton {
            width: 39px;
            height: 41px;
            border: 1px solid #E1E4EA;
            background: #fff;
            &:last-child {
                border: 1px solid #FC3F1D;
                background: #FFF1EF;
            }
        }
    }
`;

export const WrapperFormProfile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    .wrapperSerteficateHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .title {
        color: #282A42;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-flex {
            display: flex;
            flex-direction: row;
            gap: 20px;
            .column {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
            }
        }
        .row {
            display: flex;
            gap: 10px;
            .inputForm {
                flex: 1 1 20%;
                box-sizing: border-box;
                label {
                    font-size: 12px;
                    line-height: 20px;
                }
                input {
                    font-size: 14px;
                }
            }
        }
    }
`;