import { NomenclatureDTO } from '../dtoTypes'
import { Nomenclature } from '../types'

export const adaptNomenclatureDTO = (
    nomenclatureDTO: NomenclatureDTO,
): Nomenclature => ({
    id: nomenclatureDTO.id,
    name: nomenclatureDTO.name,
    absolutDiscount: nomenclatureDTO.absolut_discount,
    percentDiscount: nomenclatureDTO.percent_discount,
    count: nomenclatureDTO.count,
    oldPrice: parseFloat(nomenclatureDTO?.old_price ?? 0),
    newPrice: parseFloat(nomenclatureDTO?.new_price ?? 0),
})
