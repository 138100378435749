import React from 'react'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import { Autocomplete } from 'shared/components/Autocomplete'
import { useGeneralInfoController } from '../../../model/hooks'
import { Icon } from 'shared/components/Icons'
import { AutocompleteRow } from './styles'

export const GenericInfoForm = () => {
    const {
        isLoading,
        orderId,
        isCreate,
        counterpartiesOptions,
        currentCounterpartyOption,
        currentCounterparty,
        isButtonDisabled,
        isOneOfTheBookingsAlreadyCreated,
        isFetching,

        setSearchString,
        fetchNextPage,
        onGoNext,
        onCounterpartyChange,
        createCounterpartyAndChange,
    } = useGeneralInfoController()

    return (
        <div className='content'>
            {!isCreate && (
                <div className='row' style={{ flexWrap: 'nowrap' }}>
                    <Input
                        name=''
                        label='Номер заказа'
                        value={orderId?.toString()}
                        disabled
                    />
                    <Input name='' value='Редактирование' disabled />
                </div>
            )}
            <AutocompleteRow>
                <Autocomplete
                    disabled={isLoading}
                    value={currentCounterpartyOption ?? null}
                    onChange={onCounterpartyChange}
                    options={counterpartiesOptions}
                    label='Контактный телефон'
                    placeholder='Введите номер телефона...'
                    onLoadMore={fetchNextPage}
                    onInputChange={setSearchString}
                    isLoading={isFetching}
                    onCreateOption={createCounterpartyAndChange}
                    required
                />
            </AutocompleteRow>

            <Input
                label='ФИО'
                value={currentCounterparty.name ?? ''}
                disabled
            />
            <Input
                label='Контактный Email'
                value={currentCounterparty.email ?? ''}
                disabled
            />
            <Button
                disabled={isButtonDisabled}
                loading={isLoading}
                onClick={onGoNext}
            >
                {isOneOfTheBookingsAlreadyCreated ? 'Сохранить' : 'Далее'}
            </Button>
        </div>
    )
}
