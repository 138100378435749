import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Moment } from 'moment'

import {
    Counterparty,
    useCounterpartyAsyncAutocompleteOptions,
} from 'entities/counterparties/model'
import { Booking, useGetBookingsListQuery } from 'entities/bookings/model'
import { OrderBooking } from 'entities/orders/model'

import {
    selectActiveBookingId,
    selectBookingCounterpartyId,
    selectBookingOrderId,
    selectBookings,
} from '../../../Booking/model/slices'
import { CalendarEvent } from '../../../ReactBigCalendar/ReactBigCalendar'
import { ViewMode, ViewModeType } from './useCalendarController'

const createAlreadyCreatedBookingsEvents = (
    booking: Booking,
    currentFormOrderId: number | null,
    activeBookingId: number | null,
): CalendarEvent => ({
    id: booking.id,
    orderId: booking.order.id,
    title: <div>{booking.area.name}</div>,
    counterpartyName: booking.counterparty.fullName,
    counterpartyPhone: booking.counterparty.phone,
    start: booking.startTime ?? new Date(),
    end: booking.endTime ?? new Date(),
    resourceId: booking.area.id,
    isNew: false,
    isEdit: activeBookingId
        ? activeBookingId === booking.id
        : currentFormOrderId === booking.order.id,
})

const createCurrentFormBookingsEvents = (
    booking: OrderBooking,
    currentFormOrderId: number | null,
    activeBookingId: number | null,
    isNew: boolean,
    currentFormCounterparty?: Counterparty,
): CalendarEvent => {
    const contact = currentFormCounterparty?.contact
    return {
        id: booking?.id,
        orderId: currentFormOrderId,
        title: <div>{booking.areaName}</div>,
        counterpartyName: contact
            ? [
                  contact?.surname ?? '',
                  contact?.firstName
                      ? contact.firstName.split('')[0] + '.'
                      : '',
                  contact?.patronymic
                      ? contact.patronymic.split('')[0] + '.'
                      : '',
              ].join(' ')
            : null,
        counterpartyPhone: currentFormCounterparty?.phone ?? null,
        start: booking.startTime ?? new Date(),
        end: booking.endTime ?? new Date(),
        resourceId: booking.areaId,
        isNew,
        isEdit: activeBookingId === booking.id,
    }
}

export const useBuildCalendarEvents = (today: Moment, view: ViewModeType) => {
    const timeFilter = useMemo(() => {
        if (view === ViewMode.Day) {
            const startDayTime = today.startOf('day').toDate()
            const endDayTime = today.endOf('day').toDate()
            return {
                startTime: startDayTime,
                endTime: endDayTime,
            }
        }
        if (view === ViewMode.Week) {
            const startWeekTime = today.startOf('week').toDate()
            const endWeekTime = today.endOf('week').toDate()
            return {
                startTime: startWeekTime,
                endTime: endWeekTime,
            }
        }
        if (view === ViewMode.Month) {
            const startMonthTime = today.startOf('month').toDate()
            const endMonthTime = today.endOf('month').toDate()
            return {
                startTime: startMonthTime,
                endTime: endMonthTime,
            }
        }
        return
    }, [view, today])

    const { counterparties } = useCounterpartyAsyncAutocompleteOptions()

    const currentFormCounterpartyId = useSelector(selectBookingCounterpartyId)
    const currentFormOrderId = useSelector(selectBookingOrderId)
    const currentFormCounterparty = counterparties?.find(
        counterparty => counterparty.id === currentFormCounterpartyId,
    )
    const activeBookingId = useSelector(selectActiveBookingId)
    const { bookings: alreadyCreatedBookings } = useGetBookingsListQuery()
    const [alreadyCreatedBookingsEvents, setAlreadyCreatedBookingsEvents] =
        useState<CalendarEvent[]>([])

    useEffect(() => {
        setAlreadyCreatedBookingsEvents(
            alreadyCreatedBookings.map(booking =>
                createAlreadyCreatedBookingsEvents(
                    booking,
                    currentFormOrderId,
                    activeBookingId,
                ),
            ),
        )
    }, [alreadyCreatedBookings, activeBookingId, currentFormOrderId])

    const currentFormBookings = useSelector(selectBookings)
    const currentFormBookingsEvents = useMemo(
        () =>
            currentFormBookings.map(booking =>
                createCurrentFormBookingsEvents(
                    booking,
                    currentFormOrderId,
                    activeBookingId,
                    !alreadyCreatedBookings.find(bkg => bkg.id === booking.id),
                    currentFormCounterparty,
                ),
            ),
        [
            alreadyCreatedBookings,
            currentFormBookings,
            currentFormOrderId,
            currentFormCounterparty,
            activeBookingId,
        ],
    )

    const bookingEvents = Array.from(
        new Map(
            [...alreadyCreatedBookingsEvents, ...currentFormBookingsEvents].map(
                event => [event.id, event],
            ),
        ).values(),
    )

    return {
        alreadyCreatedBookingsEvents,
        setAlreadyCreatedBookingsEvents,
        currentFormBookingsEvents,
        bookingEvents,
    }
}
