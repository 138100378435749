import { useField, useFormikContext } from 'formik';
import { Input } from 'shared/components/Input';

interface FormikInputProps {
  name: string;
  [key: string]: any;
}

export const FormikInput = ({ name, ...props }: FormikInputProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched, setError} = helpers;
  const { validateForm } = useFormikContext();

  const clearInput = () => {
    setValue('', true);
    setTouched(true);
    validateForm();
  };

  return (
    <Input
      {...field}
      {...props}
      value={field.value}
      fieldProps={field}
      clearInput={clearInput}
      className={meta.touched && meta.error ? 'error' : ''}
    />
  );
};
