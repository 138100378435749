import { AutocompleteOption } from 'shared/components/Autocomplete'
import { FormEvent, useState } from 'react'
import { BookingStep, setBookingCurrentStep } from '../slices'
import { useDispatch } from 'react-redux'

export const PaymentTypeOptions: AutocompleteOption[] = [
    {
        value: 'part',
        label: 'Предоплата',
    },
    {
        value: 'full',
        label: 'Полная оплата',
    },
]

export const PaymentWayOptions: AutocompleteOption[] = [
    {
        value: 'cash',
        label: 'Наличными/Картой на месте',
    },
    {
        value: 'certificate',
        label: 'Сертификат',
    },
    {
        value: 'online',
        label: 'Онлайн',
    },
]

export const usePaymentFormController = () => {
    const dispatch = useDispatch()

    const [paymentType, setPaymentType] = useState<AutocompleteOption>()
    const [paymentWay, setPaymentWay] = useState<AutocompleteOption>()
    const [comment, setComment] = useState<string>()

    const isButtonDisabled = !paymentType || !paymentWay

    const onChangePaymentType = (option: AutocompleteOption) => {
        setPaymentType(option)
    }

    const onChangePaymentWay = (option: AutocompleteOption) => {
        setPaymentWay(option)
    }

    const onChangeComment = (e: FormEvent) => {
        const newComment = (e.target as HTMLInputElement).value
        setComment(newComment)
    }

    const onGoNext = async () => {
        dispatch(setBookingCurrentStep({ step: BookingStep.Booking }))
    }

    return {
        paymentType,
        paymentWay,
        comment,
        isButtonDisabled,

        onChangePaymentType,
        onChangePaymentWay,
        onChangeComment,
        onGoNext,
    }
}
