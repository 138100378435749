import { FormEvent, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { OrderBooking } from 'entities/orders/model'
import { editBooking, OrderBookingWithOptionsFields } from '../slices'

export const formatDate = (date?: Date | null) => {
    if (!date) {
        return undefined
    }
    return moment(date).format('YYYY-MM-DD')
}

export const formatTime = (date?: Date | null) => {
    if (!date) {
        return undefined
    }
    return moment(date).format('HH:mm')
}

export const useBookingFormDateTimeHelper = (activeBooking?: OrderBooking) => {
    const dispatch = useDispatch()

    const startTime = activeBooking?.startTime ?? null
    const start = useMemo(
        () => ({
            date: startTime ? formatDate(startTime) : '',
            time: startTime ? formatTime(startTime) : '',
        }),
        [startTime, activeBooking],
    )

    const endTime = activeBooking?.endTime ?? null
    const end = useMemo(
        () => ({
            date: endTime ? formatDate(endTime) : '',
            time: endTime ? formatTime(endTime) : '',
        }),
        [endTime, activeBooking],
    )

    const onEditBooking = (
        bookingId: number,
        body: OrderBookingWithOptionsFields,
    ) => {
        dispatch(editBooking({ bookingId, body }))
    }

    const onEditStartDate = (e: FormEvent) => {
        if (activeBooking) {
            const date = (e.target as HTMLInputElement).value
            const timeString = start?.time?.length ? `T${start.time}:00` : ''
            const newDateTimeString = `${date}${timeString}`
            const newDateTime = new Date(newDateTimeString)
            onEditBooking(activeBooking.id, { startTime: newDateTime })
        }
    }

    const onEditEndDate = (e: FormEvent) => {
        if (activeBooking) {
            const date = (e.target as HTMLInputElement).value
            const timeString = end?.time?.length ? `T${end.time}:00` : ''
            const newDateTimeString = `${date}${timeString}`
            const newDateTime = new Date(newDateTimeString)
            onEditBooking(activeBooking.id, { endTime: newDateTime })
        }
    }

    const onEditStartTime = (e: FormEvent) => {
        if (activeBooking) {
            const time = (e.target as HTMLInputElement).value
            const date = start?.date?.length
                ? start.date
                : moment().format('YYYY-MM-DD')
            const newDateTimeString = `${date}T${time}:00`
            const newDateTime = new Date(newDateTimeString)
            onEditBooking(activeBooking.id, { startTime: newDateTime })

            if (!end?.time || !end?.date) {
                return
            }

            const endDateTimeString = `${end.date}T${end.time}:00`
            const endDateTime = new Date(endDateTimeString)

            // if new start time < end time - everything is correct
            // else increase end time
            if (newDateTime < endDateTime) {
                return
            }

            const adjustedTime = new Date(
                newDateTime.getTime() + 15 * 60 * 1000,
            )
            onEditBooking(activeBooking.id, { endTime: adjustedTime })
        }
    }

    const onEditEndTime = (e: FormEvent) => {
        if (activeBooking) {
            const time = (e.target as HTMLInputElement).value
            const date = end?.date?.length
                ? end.date
                : moment().format('YYYY-MM-DD')
            const newDateTimeString = `${date}T${time}:00`
            const newDateTime = new Date(newDateTimeString)
            onEditBooking(activeBooking.id, { endTime: newDateTime })

            if (!start?.time || !start?.date) {
                return
            }

            const startDateTimeString = `${start.date}T${start.time}:00`
            const startDateTime = new Date(startDateTimeString)

            // if new end time > start time - everything is correct
            // else decrease start time
            if (newDateTime > startDateTime) {
                return
            }

            const adjustedTime = new Date(
                newDateTime.getTime() - 15 * 60 * 1000,
            )
            onEditBooking(activeBooking.id, { startTime: adjustedTime })
        }
    }

    return {
        start,
        end,

        onEditStartDate,
        onEditEndDate,
        onEditStartTime,
        onEditEndTime,
    }
}
