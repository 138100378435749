import { axiosApi } from 'shared/api/axios'
import { OrderEndpoint } from './constants'
import { AddBookingRequest } from '../model'
import { AddBookingToOrderResponseDTO } from '../model/dtoTypes'

export const addBookingToOrder = async ({
    orderId,
    areaId,
    startTime,
    endTime,
    comment,
    membersCount,
}: AddBookingRequest) => {
    try {
        const response = await axiosApi.post<AddBookingToOrderResponseDTO>(
            `${OrderEndpoint.bookings}?order_id=${orderId}`,
            {
                area_id: areaId,
                start_time: startTime,
                end_time: endTime,
                comment,
                members_count: membersCount,
            },
        )
        return {
            bookingId: response.data.booking_id,
            totalPrice: parseFloat(response.data.new_order_total_price),
        }
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
