import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '../Icons'
import {
    InputContainer,
    StyledInputWrapper,
    StyleBeforeIcon,
    StyledInput,
    StyleAfterIcon,
    Label,
} from './style'
import IMask from 'imask/esm/imask'
import 'imask/esm/masked/pattern'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
    label?: string
    type?: 'password' | 'text' | 'email' | 'phone' | 'number' | 'date' | 'time'
    placeholder?: string
    $beforeIcon?: string
    $afterIcon?: string
    className?: string
    required?: boolean
    disabled?: boolean
    afterIconOnClick?: () => void
    clearInput?: () => void
    name?: string
    maskConfig?: any
    fieldProps?: any
}

export const Input: React.FC<InputProps> = ({
    label,
    type = 'text',
    $beforeIcon,
    $afterIcon,
    className,
    required,
    disabled = false,
    afterIconOnClick,
    clearInput,
    name,
    fieldProps = {},
    maskConfig,
    ...props
}) => {
    const [inputType, setInputType] = useState(type === 'phone' ? 'text' : type)

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current && maskConfig) {
            const maskInstance = IMask(inputRef.current, maskConfig)
            return () => {
                maskInstance.destroy()
            }
        }
    }, [maskConfig])

    const showClearIcon =
        !disabled && fieldProps.value && fieldProps.value.length > 0

    useEffect(() => {
        setInputType(type === 'phone' ? 'text' : type)
    }, [type])

    const toggleInputType = useCallback(() => {
        setInputType(prevType =>
            prevType === 'password' ? 'text' : 'password',
        )
    }, [])

    return (
        <InputContainer className={className}>
            {label && (
                <Label>
                    {required && <span className='required'>*</span>}
                    {label}
                </Label>
            )}
            <StyledInputWrapper className='styledWrapperInput'>
                {$beforeIcon && (
                    <StyleBeforeIcon className='styledBeforeIcon'>
                        <Icon name={$beforeIcon} />
                    </StyleBeforeIcon>
                )}
                <StyledInput
                    {...fieldProps}
                    ref={inputRef}
                    disabled={disabled}
                    $beforeIcon={$beforeIcon}
                    $afterIcon={$afterIcon}
                    type={inputType}
                    pattern={inputType === 'number' ? '[0-9]*' : undefined}
                    {...props}
                    className='styledInput'
                />
                {$afterIcon && type === 'password' ? (
                    <StyleAfterIcon onClick={toggleInputType}>
                        {inputType === 'password' ? (
                            <Icon name='hidden' />
                        ) : (
                            <Icon name='show' />
                        )}
                    </StyleAfterIcon>
                ) : showClearIcon && $afterIcon === 'clearInput' ? (
                    <StyleAfterIcon onClick={clearInput || afterIconOnClick}>
                        <Icon name={$afterIcon} />
                    </StyleAfterIcon>
                ) : $afterIcon && $afterIcon !== 'clearInput' ? (
                    <StyleAfterIcon onClick={afterIconOnClick}>
                        <Icon name={$afterIcon} />
                    </StyleAfterIcon>
                ) : null}
            </StyledInputWrapper>
        </InputContainer>
    )
}
