import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'

import { useGetBookingsListQuery } from 'entities/bookings/model'
import { OrdersApi } from 'entities/orders/api'
import { RootState } from 'shared/redux/store'
import { closeSidebar } from 'shared/redux/slice/sidebar'
import {
    BookingMode,
    BookingStep,
    BookingStepType,
    clearBookingState,
    selectActiveBookingId,
    selectBookingCurrentStep,
    selectBookingFormMode,
    selectBookingOrderId,
    setActiveBookingId,
    setBookingCounterpartyId,
    setBookingFormMode,
    setBookings,
    setOrderEditStateInitialising,
    setOriginalOrder,
} from '../slices'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'

export const useBookingSidebarController = () => {
    const currentStep = useSelector(selectBookingCurrentStep)
    const dispatch = useDispatch()
    const { refetch: refetchBookings } = useGetBookingsListQuery()

    const { isOpen } = useSelector((state: RootState) => state.sidebar)
    const mode = useSelector(selectBookingFormMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const orderId = useSelector(selectBookingOrderId)

    const handleCloseSidebar = () => {
        dispatch(clearBookingState())
        dispatch(closeSidebar())
    }

    const onDeleteOrder = async () => {
        if (!orderId) {
            return
        }

        const deleteOrder = async () => {
            await OrdersApi.deleteOrder({ orderId })
            dispatch(clearBookingState())
            await refetchBookings()
        }

        dispatch(
            openModal({
                isOpen: true,
                content: (
                    <>
                        У вас есть несохраненные изменения. Вы уверены, что
                        хотите уйти?
                    </>
                ),
                config: {
                    title: 'Вы точно хотите удалить этот ордер? Все его бронирования будут удалены.',
                    btns: [
                        {
                            onClick: () => {
                                deleteOrder()
                                dispatch(closeModal())
                            },
                            title: 'Да',
                            active: true,
                        },
                        {
                            onClick: () => dispatch(closeModal()),
                            title: 'Нет',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const AccordionState = useMemo(
        () => ({
            GeneralInfo: {
                isOpen: isEdit
                    ? currentStep === BookingStep.GeneralInfo
                        ? true
                        : undefined
                    : currentStep === BookingStep.GeneralInfo,
                isDisabled: false,
                title: '1. Общая информация',
            },
            Booking: {
                isOpen: isEdit
                    ? currentStep === BookingStep.Booking
                        ? true
                        : undefined
                    : currentStep === BookingStep.Booking,
                isDisabled:
                    !isEdit &&
                    ([BookingStep.GeneralInfo] as BookingStepType[]).includes(
                        currentStep,
                    ),
                title: '2. Бронирование',
            },
            Payment: {
                isOpen: currentStep === BookingStep.Payment,
                isDisabled:
                    !isEdit &&
                    (
                        [
                            BookingStep.GeneralInfo,
                            BookingStep.Booking,
                        ] as BookingStepType[]
                    ).includes(currentStep),
                title: '3. Оплата',
            },
            Documents: {
                isOpen: currentStep === BookingStep.Documents,
                isDisabled:
                    !isEdit &&
                    (
                        [
                            BookingStep.GeneralInfo,
                            BookingStep.Booking,
                            BookingStep.Payment,
                        ] as BookingStepType[]
                    ).includes(currentStep),
                title: '4. Документы',
            },
        }),
        [currentStep],
    )

    return {
        AccordionState,
        isOpen,
        handleCloseSidebar,
        onDeleteOrder,
    }
}
