import moment from 'moment'

import { DashboardLayout } from 'app/layout'
import { ReactBigCalendar } from 'features/ReactBigCalendar'
import {
    useCalendarController,
    ViewMode,
} from 'features/BookingCalendarController/model'
import { Button } from 'shared/components/Button'
import { TabLink } from 'shared/components/TabLink'
import { linksCalendar } from 'shared/consts/linksCalendar'
import { PeriodSelector } from 'shared/components/PeriodSelector'

export const CalendarWeekPage = () => {
    const {
        currentDate,
        bookingEvents,

        onSelectSlot,
        onDropSlot,
        onClickEvent,
        onEventResize,

        handleDateChange,
        onNewBookingClick,
    } = useCalendarController({ view: ViewMode.Week })

    const DayColumnWrapper = (e: any) => {
        return (
            <div>
                {moment(e.date).format('DD')} - {moment(e.date).format('dd')}
            </div>
        )
    }

    return (
        <DashboardLayout
            header={
                <div className='dashboard__header'>
                    <TabLink list={linksCalendar} />
                    <PeriodSelector
                        period='week'
                        onDateChange={handleDateChange}
                    />
                    <div className='dashboard__right-menu'>
                        <Button onClick={onNewBookingClick}>
                            + Новая бронь
                        </Button>
                    </div>
                </div>
            }
        >
            <ReactBigCalendar
                views={{
                    week: true,
                }}
                defaultView='week'
                currentDate={currentDate}
                onDateChange={handleDateChange}
                events={bookingEvents}
                components={{
                    header: DayColumnWrapper,
                }}
                onSelectSlot={onSelectSlot}
                onDropSlot={onDropSlot}
                onEventResize={onEventResize}
                onClickEvent={onClickEvent}
            />
        </DashboardLayout>
    )
}
