import React from 'react'

import { Tab, Tabs } from 'shared/components/Tabs'
import { Accordion } from 'shared/components/Accordion'
import { Header, GenericInfoForm, BookingForm, PaymentForm } from './components'
import { BookingSidebarContainer } from './styles'
import { useBookingSidebarController } from '../model/hooks'
import { Footer } from './components/Footer/Footer'

export const BookingSidebar = () => {
    const { AccordionState, isOpen, handleCloseSidebar, onDeleteOrder } =
        useBookingSidebarController()

    return (
        <BookingSidebarContainer isExpanded={isOpen}>
            <div
                className='sibedar-top-row'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Header
                    onDeleteOrder={onDeleteOrder}
                    isSidebarOpen={isOpen}
                    handleCloseSidebar={handleCloseSidebar}
                />
                <div className='line'></div>
                <div style={{ flex: 1, width: '100%' }}>
                    <Tabs>
                        <Tab label='Физическое лицо'>
                            <Accordion
                                isOpen={AccordionState.GeneralInfo.isOpen}
                                title={AccordionState.GeneralInfo.title}
                                isOpenByDefault
                            >
                                <GenericInfoForm />
                            </Accordion>
                            <Accordion
                                isOpen={AccordionState.Booking.isOpen}
                                title={AccordionState.Booking.title}
                                disabled={AccordionState.Booking.isDisabled}
                            >
                                <BookingForm />
                            </Accordion>
                            <Accordion
                                isOpen={AccordionState.Payment.isOpen}
                                title={AccordionState.Payment.title}
                                disabled={AccordionState.Payment.isDisabled}
                            >
                                <PaymentForm />
                            </Accordion>
                            <Accordion
                                isOpen={AccordionState.Documents.isOpen}
                                title={AccordionState.Documents.title}
                                disabled={AccordionState.Documents.isDisabled}
                            >
                                hello
                            </Accordion>
                        </Tab>
                        <Tab label='Юридическое лицо'>
                            <Accordion title='1. Общая информация'>
                                hello
                            </Accordion>
                            <Accordion title='2. Бронирование'>hello</Accordion>
                            <Accordion title='3. Оплата'>hello</Accordion>
                            <Accordion title='4. Документы'>hello</Accordion>
                        </Tab>
                    </Tabs>
                </div>
                <Footer />
            </div>
        </BookingSidebarContainer>
    )
}
