import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, { forwardRef } from 'react'
import moment, { Moment } from 'moment'
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import {
    WrapperCalendar,
    StyledEditEventsWrapper,
    StyledNewEventsWrapper,
    StyledReadyEventsWrapper,
} from './style'

export interface CalendarEvent {
    allDay?: boolean
    id?: number | null
    orderId: number | null
    counterpartyName: string | null
    counterpartyPhone: string | null
    title: React.ReactNode | null
    start: Date | null
    end: Date | null
    resourceId: number | null
    isNew: boolean
    isEdit: boolean
}

// TODO: fix types
interface IPropsReactBigCalendar {
    views: any
    defaultView: View
    min?: Date
    max?: Date
    currentDate: Moment
    onDateChange: (date: Moment) => void
    events?: CalendarEvent[] | undefined
    resources?: { id: number; title: string }[]
    components?: any
    onEventResize?: (props: any) => void
    onDropSlot?: (props: any) => void
    onSelectSlot?: (props: any) => void
    onClickEvent?: (props: any) => void
}

export const ReactBigCalendar = ({
    views,
    defaultView,
    min,
    max,
    currentDate,
    onDateChange,
    events,
    resources,
    components,
    onDropSlot,
    onEventResize,
    onSelectSlot,
    onClickEvent,
}: IPropsReactBigCalendar) => {
    const localizer = momentLocalizer(moment)
    const DnDCalendar = withDragAndDrop(Calendar)

    const DayColumnWrapper = ({
        children,
        className,
        style,
        innerRef,
    }: any) => {
        return (
            <div
                className={`day-header ${className}`}
                style={style}
                ref={innerRef}
            >
                {children}
            </div>
        )
    }

    const dayCol = forwardRef((dayColumnWrapperProps, ref) => {
        return <DayColumnWrapper {...dayColumnWrapperProps} innerRef={ref} />
    })
    dayCol.displayName = 'dayCol'

    const eventsWrapper = ({ children, event, ...props }: any) => {
        if (event.isEdit) {
            return (
                <StyledEditEventsWrapper {...props}>
                    {children}
                </StyledEditEventsWrapper>
            )
        }

        if (event.isNew) {
            return (
                <StyledNewEventsWrapper {...props}>
                    {children}
                </StyledNewEventsWrapper>
            )
        }

        return (
            <StyledReadyEventsWrapper {...props}>
                {children}
            </StyledReadyEventsWrapper>
        )
    }

    const event = ({ event }: { event: CalendarEvent }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}
            >
                <span>
                    {event.isNew
                        ? 'Новое бронирование'
                        : event.isEdit
                          ? 'Редактирование бронирование'
                          : 'Готовое бронирование'}
                </span>
                <br />
                {event.counterpartyName ? (
                    <span style={{ fontSize: 12 }}>
                        ФИО: {event.counterpartyName ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.counterpartyPhone ? (
                    <span style={{ fontSize: 12 }}>
                        Номер телефона: {event.counterpartyPhone ?? ''}
                    </span>
                ) : (
                    <></>
                )}
            </div>
        )
    }

    return (
        <WrapperCalendar>
            <DnDCalendar
                onDragStart={onClickEvent}
                onEventResize={onEventResize}
                onEventDrop={onDropSlot}
                onSelectSlot={onSelectSlot}
                localizer={localizer}
                views={views}
                defaultView={defaultView}
                timeslots={2}
                step={15}
                min={min ?? new Date(new Date().setHours(0, 0, 0, 0))}
                max={max ?? new Date(new Date().setHours(23, 59, 59, 999))}
                date={currentDate.toDate()}
                onNavigate={date => onDateChange(moment(date))}
                toolbar={false}
                events={events}
                resources={resources}
                components={{
                    dayColumnWrapper: dayCol,
                    eventWrapper: eventsWrapper,
                    event,
                    ...components,
                }}
                enableAutoScroll={false}
                tooltipAccessor={null}
                selectable
            />
        </WrapperCalendar>
    )
}
