import { AddNomenclaturesResponse } from '../types'
import { AddNomenclaturesResponseDTO } from '../dtoTypes'

export const adaptAddNomenclaturesResponseDTO = (
    addNomenclaturesResponse: AddNomenclaturesResponseDTO,
): AddNomenclaturesResponse => ({
    bookingId: addNomenclaturesResponse.booking_id,
    oldOrderTotalPrice: parseFloat(
        addNomenclaturesResponse.old_order_total_price,
    ),
    newOrderTotalPrice: parseFloat(
        addNomenclaturesResponse.new_order_total_price,
    ),
    nomenclature: {
        name: addNomenclaturesResponse.nomenclature.name,
        absolutDiscount: addNomenclaturesResponse.nomenclature.absolut_discount,
        percentDiscount: addNomenclaturesResponse.nomenclature.percent_discount,
        count: addNomenclaturesResponse.nomenclature.count,
        oldPrice: parseFloat(addNomenclaturesResponse.nomenclature.old_price),
        newPrice: parseFloat(addNomenclaturesResponse.nomenclature.new_price),
    },
})
