import { DashboardLayout } from "app/layout"; 
import { Button } from "shared/components/Button"; 
import { card, dropdownPeriod, trafic } from "./statics"; 
import { Card } from "./components/Card"; 
import { useEffect, useState } from "react"; 
import { Row, Box, ModalWrapper, ButtonCalendar, WrapperDate } from "./style"; 
import { Dropdown } from "shared/components/Dropdown"; 
import { Modal } from "shared/components/Modal"; 
import { Input } from "shared/components/Input"; 
import moment from "moment"; 
import { Calendar } from "features/Calendar"; 
import { ChartLine } from "shared/components/ChartLine"; 
import { keepPreviousData, useQuery } from "@tanstack/react-query"; 
import { getMetrics } from "./api/getMetrics"; 
import { getMonthsFromDate } from "shared/utils/getPreviousMonths"; 
import { OrdersTable } from "features/OrdersTable";
import { aggregateDataByHour } from "shared/utils/aggregateDataByHour";
import { AggregatedData } from "shared/types/metricsTypes";
 
interface MetricsTotal { 
    total_revenue?: number; 
    total_canceled_bookings?: number; 
    total_returns?: number; 
    total_day_orders?: number; 
    week_start?: string; 
} 
 
export const Dashboard = () => { 
    const [selectedPeriod, setSelectedPeriod] = useState(dropdownPeriod[0]); 
    const [aggregateData, setAggregateData] = useState<AggregatedData[] | null>(null);
    const [labels, setLables] = useState<string[] | null>([]);

    const [query, setQuery] = useState<string>(`daily?day=${moment().format("YYYY-MM-DD")}`); 

    const { isLoading, isError, data, isFetching, isPending, refetch} = useQuery({ 
        queryKey: ["getMetrics", selectedPeriod, query],  
        queryFn: () => {
            return getMetrics(query);
        },
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        retry: 3,
        staleTime: 1000 * 60
    }); 


    useEffect(() => { 
        switch(selectedPeriod) { 
            case "Сегодня": 
                setQuery(`daily?day=${moment().format("YYYY-MM-DD")}`);
                break; 
            case "Неделя": 
                setQuery(`weekly?start_day=${moment().format("YYYY-MM-DD")}&end_day=${moment().add(7, "days").format("YYYY-MM-DD")}`) 
                break; 
            case "Месяц": 
                setQuery(`monthly?month=${moment().format("YYYY-MM-DD")}`) 
        } 
    }, [selectedPeriod])

    useEffect(() => {
        if(data && selectedPeriod === "Сегодня") {
            const aggregated = aggregateDataByHour(data[0].data);
            setAggregateData(aggregated as any)
            setLables(aggregateData && aggregateData.map((item) => moment(item.hour).format("HH")));
        }
        if(data && selectedPeriod === "Неделя") {
            setLables(data[0].data.map((item) => moment(item.created_at).format("dddd")));
            setAggregateData(data[0].data as any);
        }
        if(data && selectedPeriod === "Месяц") {
            
        }
    }, [data])

    

    return ( 
        <DashboardLayout header={ 
            <div className="dashboard__header"> 
                <div className="dashboard__header-title">Дашборд</div> 
                <div className="dashboard__right-menu"> 
                    <Dropdown selected={selectedPeriod} setSelected={setSelectedPeriod} /> 
                </div> 
            </div> 
        } > 
                <Row> 
                    {!isLoading && data &&  
                        <> 
                            {card.map((item, index) => ( 
                                <Card  
                                    key={index} 
                                    title={item.title} 
                                    amount={data[0]?.total?.[item.keyData as keyof MetricsTotal] || 0} 
                                    icon={item.icon} 
                                    loading={isLoading && isPending} 
                                    showCurrency={item.showCurrency} 
                                /> 
                            ))} 
                        </>} 
                </Row> 
                <Row> 
                    <div className="chart"> 
                        {/* {selectedPeriod === "Месяц" && ( 
                            <Dropdown selected={selectedMonth} setSelected={setSelectedMonth} /> 
                        )} */} 
                        {data && aggregateData &&  
                            <ChartLine 
                                labels={labels} 
                                dataAccepted={aggregateData.map((item) => item.total_orders)} 
                                dataCancelled={aggregateData.map((item) => item.canceled_bookings)} 
                            /> 
                        } 
                    </div> 
                </Row> 
                <Row> 
                    <OrdersTable />
                </Row> 
        </DashboardLayout> 
    ); 
}