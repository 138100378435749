import { Dispatch, SetStateAction } from 'react'
import { SlotInfo } from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop'

import { BookingsApi } from 'entities/bookings/api'

import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { useGetBookingsListQuery } from 'entities/bookings/model'

import { openSidebar, SideBar } from 'shared/redux/slice/sidebar'

import {
    ActiveBookingFormTab,
    addBooking,
    BookingMode,
    editBooking,
    selectBookingMode,
    selectBookingOrderId,
    selectOrderEditStateInitialising,
    setActiveBookingId,
    setBookingActiveTab,
    setBookingFormMode,
    setBookingOrderId,
} from '../../../Booking/model/slices'
import { CalendarEvent } from '../../../ReactBigCalendar/ReactBigCalendar'
import { useFillStore } from '../../../Booking/model/hooks'

interface UseCalendarActionsParams {
    alreadyCreatedBookingsEvents: CalendarEvent[]
    setAlreadyCreatedBookingsEvents: Dispatch<SetStateAction<CalendarEvent[]>>
}

export const useCalendarActions = ({
    alreadyCreatedBookingsEvents,
    setAlreadyCreatedBookingsEvents,
}: UseCalendarActionsParams) => {
    const dispatch = useDispatch()
    const mode = useSelector(selectBookingMode)
    const isEditStateInitialising = useSelector(
        selectOrderEditStateInitialising,
    )

    const activeOrderId = useSelector(selectBookingOrderId)
    const updateBookings = useFillStore()
    const { areasResources } = useAreasResourcesQuery()
    const { refetch: refetchAlreadyCreatedBookings } = useGetBookingsListQuery()

    const onSelectSlot = (props: SlotInfo) => {
        if (mode === BookingMode.Edit && isEditStateInitialising) {
            return
        }
        const startTime = new Date(props.start)
        const endTime =
            props.action === 'click'
                ? new Date(
                      new Date(props.start).setHours(startTime.getHours() + 1),
                  )
                : new Date(props.end)
        const areaId = Number(props.resourceId)
        const areaName = props?.resourceId
            ? (areasResources?.find(res => res.id === areaId)?.title ?? '')
            : ''
        const bookingId = new Date().getMilliseconds() ** 2
        dispatch(
            addBooking({
                id: bookingId,
                areaPrice: 0,
                comment: '',
                nomenclatures: [],
                nomenclaturesPrice: 0,
                membersCount: 0,
                areaId,
                areaName,
                startTime,
                endTime,
            }),
        )
        dispatch(setActiveBookingId({ bookingId }))
        dispatch(openSidebar(SideBar.Booking))
        dispatch(setBookingActiveTab({ tab: ActiveBookingFormTab.Booking }))
    }

    const updateBookingByCalendarAction = async (
        props: EventInteractionArgs<CalendarEvent>,
    ) => {
        const event = props.event
        const areaId = Number(props.resourceId)
        const areaName = props.resourceId
            ? (areasResources?.find(res => res.id === areaId)?.title ?? null)
            : null
        const startTime = new Date(props.start)
        const endTime = new Date(props.end)
        const bookingId = event.id
        const orderId = event.orderId

        if (bookingId) {
            dispatch(
                editBooking({
                    bookingId,
                    body: { startTime, endTime, areaId, areaName },
                }),
            )
        }

        if (!orderId || !bookingId || event.isNew) {
            return
        }

        const updatedAlreadyCreatedBookingsEvents =
            alreadyCreatedBookingsEvents.map(booking => {
                if (booking.id === bookingId) {
                    return {
                        ...booking,
                        start: startTime,
                        end: endTime,
                        resourceId: areaId,
                    }
                }
                return booking
            })

        setAlreadyCreatedBookingsEvents(updatedAlreadyCreatedBookingsEvents)
        await BookingsApi.updateBookings(
            { orderId, bookingId },
            { startTime, endTime, areaId },
        )
        await refetchAlreadyCreatedBookings()
    }

    const onDropSlot = async (props: EventInteractionArgs<CalendarEvent>) => {
        await updateBookingByCalendarAction(props)
    }

    const onEventResize = async (
        props: EventInteractionArgs<CalendarEvent>,
    ) => {
        await updateBookingByCalendarAction(props)
    }

    const onClickEvent = (props: EventInteractionArgs<CalendarEvent>) => {
        const orderId = props.event.orderId
        const id = props.event.id
        dispatch(openSidebar(SideBar.Booking))

        if (id) {
            dispatch(setActiveBookingId({ bookingId: id }))
        }

        if (!props.event.isNew) {
            if (orderId && id && orderId !== activeOrderId) {
                dispatch(setBookingOrderId({ orderId }))
                dispatch(setBookingFormMode({ mode: BookingMode.Edit }))
                updateBookings(orderId, id).then()
            }
            return
        }
    }

    return {
        onSelectSlot,
        onDropSlot,
        onEventResize,
        onClickEvent,
    }
}
