import React from 'react'

import { Autocomplete } from 'shared/components/Autocomplete'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import {
    PaymentTypeOptions,
    PaymentWayOptions,
    usePaymentFormController,
} from '../../../model/hooks/usePaymentFormController'

export const PaymentForm = () => {
    const {
        paymentType,
        paymentWay,
        comment,
        isButtonDisabled,

        onChangePaymentType,
        onChangePaymentWay,
        onChangeComment,
        onGoNext,
    } = usePaymentFormController()

    return (
        <div className='content'>
            <Autocomplete
                value={paymentType ?? null}
                onChange={onChangePaymentType}
                options={PaymentTypeOptions}
                label='Тип оплаты'
                placeholder='Выберете тип оплаты'
                required
            />
            <Autocomplete
                value={paymentWay ?? null}
                onChange={onChangePaymentWay}
                options={PaymentWayOptions}
                label='Способ оплаты'
                placeholder='Выберете способ оплаты'
                required
            />
            <Input
                label='Комментарий'
                value={comment}
                onChange={onChangeComment}
            />
            <Button disabled={isButtonDisabled} onClick={onGoNext}>
                Далее
            </Button>
        </div>
    )
}
