import React, { FC } from 'react'

import { Icon } from 'shared/components/Icons'
import { HeaderContentItem, HeaderContainer } from './styles'

interface HeaderProps {
    isSidebarOpen: boolean
    handleCloseSidebar: () => void
    onDeleteOrder: () => void
}

export const Header: FC<HeaderProps> = ({
    isSidebarOpen,
    handleCloseSidebar,
    onDeleteOrder,
}) => (
    <HeaderContainer>
        <HeaderContentItem>
            <div className='wrapper'>
                <button onClick={() => handleCloseSidebar()}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                    >
                        <path
                            d='M14.5 17L9.5 12L14.5 7'
                            stroke='#8181A5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                </button>
                <span>Назад</span>
            </div>
        </HeaderContentItem>
        <HeaderContentItem>
            <button
                onClick={() => {
                    onDeleteOrder()
                }}
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='39'
                    height='39'
                    viewBox='0 0 39 39'
                    fill='none'
                >
                    <rect
                        x='38.5'
                        y='38.5'
                        width='38'
                        height='38'
                        rx='7.5'
                        transform='rotate(-180 38.5 38.5)'
                    />
                    <path
                        d='M13.3431 13.6569L24.6569 24.9706M24.6569 13.6569L13.3431 24.9706'
                        stroke='#FC3F1D'
                        strokeLinecap='round'
                    />
                </svg>
            </button>
            <button>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                >
                    <rect
                        x='16'
                        y='4'
                        width='4'
                        height='16'
                        rx='1'
                        fill='#8181A5'
                    />
                    <rect
                        x='4'
                        y='4'
                        width='10'
                        height='16'
                        rx='1'
                        fill='#8181A5'
                    />
                </svg>
            </button>
            <button onClick={() => handleCloseSidebar()}>
                <Icon
                    className='back'
                    name='arrowRight'
                    style={{
                        transform: isSidebarOpen
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                    }}
                />
            </button>
        </HeaderContentItem>
    </HeaderContainer>
)
