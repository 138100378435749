import { formatNumber } from "shared/utils/useFormattedNumber";

export const columns = [
    {
        header: "",
        id: "photo",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {typeof value === 'object' ? <div className="bodyCellImgBg"></div> : <img src={value} alt={"Фото"} />}
                </div>
            )
        },
        accessorKey: "photo.read_url"
    },
    {
        header: "Наименование",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                typeof value === 'object' ? "ИмяСтатик" : 
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">{value}</div>
                    <div className="wrapperCellDesc">#{row && row.row.original.id}</div>
                </div>
            )
        },
        accessorKey: "name"
    },
    {
        header: "Наличие",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">{formatNumber(row.renderValue())}</div>
                    <div className="wrapperCellDesc">В наличиии</div>
                </div>
            )
        },
        accessorKey: "count"
    },
    {
        header: "Заработано (₽)",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">{formatNumber(row.renderValue())}</div>
                    <div className="wrapperCellDesc">Заработано</div>
                </div>
            )
        },
        accessorKey: "total_earned"
    },
    {
        header: "Цена (₽)",
        cell: (row: any) => {
            return (
                <div className="wrapperCell">
                    <div className="wrapperCellTitle">{row.renderValue() ? formatNumber(row.renderValue()) : 0}</div>
                    <div className="wrapperCellDesc">Цена</div>
                </div>
            )
        },
        accessorKey: "price"
    },
    {
        header: "Комментарий",
        cell: (row: any) => {
            return (
                <div className="wrapperCellDesc comment">{row.renderValue()}</div>
            )
        },
        accessorKey: "comment"
    },
]