import { useMemo, useState } from 'react'
import moment, { Moment } from 'moment/moment'
import { useDispatch } from 'react-redux'

import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { openSidebar, SideBar } from 'shared/redux/slice/sidebar'
import { useCalendarActions } from './useCalendarActions'
import { useNavigationBlocker } from './useNavigationBlocker'
import { useBuildCalendarEvents } from './useBuildCalendarEvents'

export const ViewMode = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
}

export type ViewModeType = (typeof ViewMode)[keyof typeof ViewMode]

interface UseCalendarControllerParams {
    view: ViewModeType
}

export const useCalendarController = ({
    view,
}: UseCalendarControllerParams) => {
    useNavigationBlocker()

    const dispatch = useDispatch()
    const [currentDate, setCurrentDate] = useState<Moment>(moment())
    const { areasResources } = useAreasResourcesQuery()

    const areas = useMemo(() => areasResources, [areasResources])
    const {
        alreadyCreatedBookingsEvents,
        setAlreadyCreatedBookingsEvents,
        bookingEvents,
    } = useBuildCalendarEvents(currentDate, view)

    const { onSelectSlot, onDropSlot, onEventResize, onClickEvent } =
        useCalendarActions({
            alreadyCreatedBookingsEvents,
            setAlreadyCreatedBookingsEvents,
        })

    const handleDateChange = (date: Moment) => {
        setCurrentDate(date)
    }

    const onNewBookingClick = () => {
        dispatch(openSidebar(SideBar.Booking))
    }

    return {
        currentDate,
        areasResources: areas,
        bookingEvents,

        onSelectSlot,
        onDropSlot,
        onClickEvent,
        onEventResize,

        handleDateChange,
        onNewBookingClick,
    }
}
