import { useQuery } from "@tanstack/react-query";
import { getEmployeesSingle } from "../api/getEmployeesSingle";
import { TabsWrapper, WrapperAvatar } from "./style";
import { Tab, Tabs } from "shared/components/Tabs";
import { Avatar } from "shared/components/Avatar";
import { StyledLine } from "shared/styles/GlobalStyle";
import { Formik, FormikHelpers } from "formik";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { getInitialValues } from "../model/profile/initialValues";
import { GeneralData, NameUserData } from "../model/profile/generalData";
import { DocumentsData } from "../model/profile/documentsData";
import { PlaceData } from "../model/profile/placeData";
import { useEffect, useState } from "react";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { UploadFile } from "entities/uploadFiles";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { getChangedValues } from "shared/utils/getChangedValues";
import { initialValues } from "../model/createProfile/initialValues";
import { patchEmployees } from "../api/patchEmployes";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { validateSchema, validateSchemaProfile } from "../model/profile/validateSchema";
import { dir } from "console";
import { Loader } from "shared/components/Loader";

interface IPropsFile {
    id: number;
    name: string;
    hash_name?: string;
    read_url?: string;
}

interface IPropsEmployeesProfile {
    isEditable: boolean;
    formikRef: React.Ref<FormikHelpers<any>>;
    setIsEditable: (e: boolean) => void;
    setEditButtonText: (e: string) => void;
    setDisabled: (e: boolean) => void;
}

export const EmployeesProfile = ({
    isEditable,
    formikRef,
    setIsEditable,
    setEditButtonText,
    setDisabled
}:IPropsEmployeesProfile) => {
    const {id} = useParams();

    const [name, setName] = useState('');
    const [photo, setPhoto] = useState<File | null>(null);
    const [newFiles, setNewFiles] = useState<IPropsFile[]>([]);
    const [removedFiles, setRemovedFiles] = useState<IPropsFile[]>([]);

    const dispatch = useDispatch();
    const { handleFileUpload } = useFileUploadUp();

    const {isLoading, isError, data, isSuccess, refetch} = useQuery({
        queryKey: ["getEmployeesSingle", id],
        queryFn: () => getEmployeesSingle(id),
        enabled: !!id,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (data && data.user_ad_personal_info) {
            const { surname, first_name, patronymic } = data.user_ad_personal_info;
            const fullName = [surname, first_name, patronymic].filter(Boolean).join(' ');
            setName(fullName);
        }
    }, [isLoading, data]);

    const onSubmit = async(values: any) => {
        const { uploadedPhotoInfo, uploadedFilesInfo } = await handleFileUpload(photo, newFiles as any || []);
        const updatedValues = {
            // ...getChangedValues(initialValues, values),
            ...values,
            role: 2,
        };
        if (uploadedPhotoInfo) {
            updatedValues.profile_photo_hash_name = uploadedPhotoInfo.hash_name;
        }
        if (removedFiles.length > 0) {
            updatedValues.removed_document_photos_hash_names = removedFiles.map((file) => file.hash_name);
            setRemovedFiles([]);
        }

        if (newFiles.length > 0) {
            updatedValues.new_document_photos_hash_names = uploadedFilesInfo.map((file, index) => ({
                hash_name: file.hash_name,
                file_name: newFiles[index].name
            }))
            setNewFiles([]);
        }

        console.log(updatedValues);

        patchEmployees(
            updatedValues as any,
            refetch,
            setIsEditable,
            setEditButtonText,
            dispatch,
            id
        ).then((data) => {
            
        })
    }    

    const handlePhotoChange = (photo: File) => {
        setPhoto(photo);
    };

    if(isLoading) {
        return <Loader />
    }

    return (
        <>
            {isLoading ? <Loader /> :
                <Formik
                    innerRef={formikRef as any}
                    initialValues={getInitialValues(data)}
                    onSubmit={onSubmit}
                    validationSchema={validateSchemaProfile}
                    // validateOnChange={true}
                    // validateOnBlur={true}
                >
                    {({ isValid }) => {
                        setDisabled(!isValid);
                        return (
                            <TabsWrapper>
                                <Tabs>
                                    <Tab label="Профиль">
                                        <WrapperAvatar>
                                            {isEditable ? (
                                                <UploadAvatar 
                                                    initialPhoto={data?.profile_photo ? data?.profile_photo?.read_url : null}
                                                    onPhotoChange={handlePhotoChange}
                                                />
                                            ) : (
                                                <Avatar photo={data?.profile_photo ? data?.profile_photo?.read_url : ''} name={name}
                                                jobs={data?.role?.name} />
                                            )}
                                        </WrapperAvatar>
                                        <StyledLine margin="20px 0"/>
                                        {isEditable && <WrapperFormRow title="ФИО" formData={NameUserData.map(row => row.map(item => ({ ...item, disabled: !isEditable })))} />}
                                        <WrapperFormRow
                                            title="Общие данные" 
                                            formData={GeneralData.map(row => 
                                                row.map(item => ({ 
                                                    ...item, 
                                                    disabled: item.name === "created_at" ? true : !isEditable,
                                                    placeholder: isEditable ? item.placeholder : ""
                                                })
                                            ))} />
                                    </Tab>
                                    <Tab label="Документы">
                                        <WrapperFormRow 
                                            title="Документы" 
                                            formData={DocumentsData.map(row => 
                                                row.map(item => ({ 
                                                    ...item, 
                                                    disabled: item.name === "created_at" ? true : !isEditable,
                                                    placeholder: isEditable ? item.placeholder : ""
                                                })
                                            ))} 
                                        />
                                        <div className="container">
                                            <div className="row">
                                                <UploadFile 
                                                    file={data?.user_ad_personal_info.document_photos ? data?.user_ad_personal_info.document_photos as any : []} 
                                                    disabled={isEditable}
                                                    onNewFilesChange={setNewFiles}
                                                    onRemovedFilesChange={setRemovedFiles}
                                                />
                                            </div>
                                        </div>
                                        <WrapperFormRow 
                                            title="Место прописки"
                                            formData={PlaceData.map(row => 
                                                row.map(item => ({ 
                                                    ...item, 
                                                    disabled: item.name === "created_at" ? true : !isEditable,
                                                    placeholder: isEditable ? item.placeholder : ""
                                                })
                                            ))} 
                                        />
                                    </Tab>
                                </Tabs>
                            </TabsWrapper>
                        )
                    }}
                </Formik>
            }
        </>
    )
}